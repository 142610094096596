import { validateNameWithNumbers } from "../../../utils/utils";
import { POSITIVE_NUMBER_REGEX, TIME_OF_DAY_24_HOURS_REGEX } from '../../../constants/regex';


export default (values) => {
    let errors = {};

    if (!values.type) {
        errors.type = "Seleccione un Tipo de Instalación"
    }

    if (!values.description) {
        errors.description = "Ingrese la descripción";
    }

    if (typeof values.detailImage == "object" && !values.detailImage.type.includes("image/")) {
        errors.detailImage = "Debe cargar una imagen"
    }

    if (Array.isArray(values.openingIntervals)) {

        if (values.openingIntervals.length === 0)
            errors.openingIntervals = "Defina al menos un intervalo horario"
    }

    if (values.newClosesAt) {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newClosesAt)) {
            errors.newClosesAt = "El horario debe estar en formato 24 horas."
        }
    }

    if (values.newOpensAt) {
        if (!TIME_OF_DAY_24_HOURS_REGEX.test(values.newOpensAt)) {
            errors.newOpensAt = "El horario debe estar en formato 24 horas."
        }
    }

    if (values.newShiftPrice) {
        if (!POSITIVE_NUMBER_REGEX.test(values.newShiftPrice)) {
            errors.newShiftPrice = "El precio del turno debe ser un número no negativo"
        }
    }

    return errors;
}