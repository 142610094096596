import { createStore } from "redux";

const saveToLocalStorage = (state) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch (e) {
  }
};

const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem('state');
    return stateStr ? JSON.parse(stateStr) : null;
  } catch (e) {
    return {};
  }
};

export const SET_CLUB = 'SET_CLUB';

function club(state = null, { club, type }) {
  switch (type) {
    case SET_CLUB:
      return club;
    default:
      return state;
  }
}

const persistedStore = loadFromLocalStorage();

const store = createStore(club, persistedStore);

store.subscribe(() => {
  saveToLocalStorage(store.getState());
});

export default store;