import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateTopic from './validator';
import TopicFormComponent from "./TopicFormComponent";
import * as Swal from "sweetalert2";
import { getChangedValues, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    title: "Título",
    color: "Color"
};

const INITIAL_STATE = {
    title: "",
    color: ""
};

const TopicFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const isNewTopic = objectIsEmpty(data);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateTopic(values));

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
        } else if (props.location.state) {
            const { id, title, color } = props.location.state;

            if (id && title && color) {
                const newData = { _id: id, title, color }
                setData(newData)
                resetValues(newData);
            }
        }
    }, [props, resetValues]);


    const handleClose = () => props.history.push('/home/topics');

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Tópico",
            text: `¿Estás seguro de que querés eliminar al Tópico '${values.title}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar Tópico',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`topics/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/topics',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewTopic) {
                post('topics', {
                    title: values.title,
                    color: values.color,
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/topics',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push('/home/topics');
                    return;
                }
                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });
                patch(`topics/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/topics',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <TopicFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewTopic={isNewTopic}
        />
    );
};

export default TopicFormContainer;