import React, { useState } from 'react';
import {
    CircularProgress,
    Fab,
    Hidden,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    withStyles,
    useTheme,
    useMediaQuery
} from "@material-ui/core/index";
import { Add, Create, Delete, Visibility } from '@material-ui/icons/index';
import styles from './styles';
import { Link } from 'react-router-dom';
import Loading from "../Loading";
import { SuccessToast } from '..';

const TableComponent = ({
    classes,
    data,
    dataKey,
    path,
    newMessage,
    columns,
    handleOpenForm,
    handleDelete,
    handleChangePage,
    successMessage,
    onCloseToast,
    navStateMapper,
    setEditable,
    setDeletable,
    setViewable,
    ROWS_PER_PAGE,
    showPagination,
    canAddNew,
    extraActions,
    extraHeaderButton
}) => {

    if (!ROWS_PER_PAGE)
        ROWS_PER_PAGE = 10;

    const theme = useTheme();
    const [rowIdOver, setRowIdOver] = useState('');
    const emptyRows = ROWS_PER_PAGE - Math.min(ROWS_PER_PAGE, data[dataKey].length - data.page * ROWS_PER_PAGE);
    const handleOver = rowId => setRowIdOver(rowId);
    const handleLeave = () => setRowIdOver('');
    const smallScreen = useMediaQuery(theme.breakpoints.down(1020));

    if (!navStateMapper)
        navStateMapper = () => { }

    if (!setEditable)
        setEditable = () => true;

    if (!setDeletable)
        setDeletable = () => true;

    if (!setViewable)
        setViewable = () => true;

    if (typeof showPagination == "undefined")
        showPagination = true

    if (typeof canAddNew == "undefined")
        canAddNew = true

    const renderTableHeader = () => (
        columns.map(column => {
            const Cell = () => (
                <TableCell
                    style={{ fontWeight: 600, minWidth: column.minWidth }}
                    classes={{ root: classes.tableHead }}
                >
                    {column.label}
                </TableCell>
            );

            if (column.hidden) {
                return <Hidden key={column.id} {...column.hidden}>
                    <Cell />
                </Hidden>
            } else {
                return <Cell key={column.id} />
            }
        })
    );

    const renderTableRow = (row, stateToSend = undefined) => (
        columns.map(column => {
            const cellProps = {
                onClick: () => handleOpenForm(row._id, stateToSend),
                key: column.id,
                classes: { root: classes.tableCell },
            };
            if (column.hidden) {
                return <Hidden key={column.id} {...column.hidden}>
                    <TableCell {...cellProps}>
                        {column.field && row[column.id] ? row[column.id][column.field] : row[column.id]}
                    </TableCell>
                </Hidden>
            } else {
                return <TableCell {...cellProps}>
                    {column.field && row[column.id] ? row[column.id][column.field] : row[column.id]}
                </TableCell>
            }
        })
    );

    const renderActions = (row, stateToSend = undefined, modifiable) => (
        <div className={classes.iconsContainer}>

            { extraActions && extraActions(row)}

            {modifiable.viewable ?
                <Link to={{
                    pathname: path + row._id,
                    state: stateToSend
                }}>
                    <IconButton>
                        <Visibility />
                    </IconButton>
                </Link> : null
            }

            {modifiable.editable ?
                <Link to={{
                    pathname: path + row._id,
                    editMode: true,
                    state: stateToSend
                }}>
                    <IconButton>
                        <Create />
                    </IconButton>
                </Link> : null
            }

            {modifiable.deleteable ?
                <IconButton onClick={() => handleDelete(row._id)}>
                    <Delete className={classes.icon} />
                </IconButton> : null
            }


        </div>
    );

    return (
        <div>
            <Paper className={classes.root}>
                {
                    data.loading ? (
                        <Loading />
                    ) : (
                            <div>
                                <TableContainer>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {renderTableHeader()}
                                                <TableCell className={classes.creationButtonsContainer}>
                                                    {
                                                        extraHeaderButton && (
                                                            <div
                                                                key="extra"
                                                                className={classes.creationButton}
                                                            >
                                                                <Fab
                                                                    size="small"
                                                                    color="primary"
                                                                    aria-label={extraHeaderButton.ariaLabel}
                                                                    variant={smallScreen ? "round" : "extended"}
                                                                    classes={{ root: classes.fabIcon }}
                                                                    onClick={extraHeaderButton.clickHandler}
                                                                    disabled={!canAddNew}
                                                                >
                                                                    {extraHeaderButton.Icon}
                                                                    {
                                                                        !smallScreen &&
                                                                        <p style={{ color: 'white', margin: 0, paddingLeft: 5 }}>
                                                                            {extraHeaderButton.label}
                                                                        </p>
                                                                    }
                                                                </Fab>
                                                            </div>
                                                        )
                                                    }
                                                    <div
                                                        key="create"
                                                        className={classes.creationButton}
                                                    >
                                                        {
                                                            data.updating
                                                                ? <CircularProgress size={24} />
                                                                : <div style={{ width: 30 }} />
                                                        }
                                                        <Fab
                                                            size="small"
                                                            color="primary"
                                                            aria-label="add"
                                                            variant={smallScreen ? "round" : "extended"}
                                                            classes={{ root: classes.fabIcon }}
                                                            onClick={() => handleOpenForm()}
                                                            disabled={!canAddNew}
                                                        >
                                                            <Add style={{ color: 'white' }} />
                                                            {
                                                                !smallScreen &&
                                                                <p style={{ color: 'white', margin: 0, paddingLeft: 5 }}>
                                                                    {newMessage}
                                                                </p>
                                                            }
                                                        </Fab>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                data[dataKey]
                                                    .slice(data.page * ROWS_PER_PAGE, data.page * ROWS_PER_PAGE + ROWS_PER_PAGE)
                                                    .map(row => {
                                                        const navState = navStateMapper(row)
                                                        const showActions = rowIdOver === row._id || smallScreen;
                                                        const modifiable = {
                                                            editable: setEditable(row),
                                                            deleteable: setDeletable(row),
                                                            viewable: setViewable(row)
                                                        }
                                                        return (
                                                            <TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={row._id}
                                                                onMouseLeave={() => handleLeave()}
                                                                onMouseOver={() => handleOver(row._id)}
                                                            >
                                                                {renderTableRow(row, navState)}
                                                                <TableCell
                                                                    style={{ paddingTop: 2, paddingBottom: 2 }}
                                                                    classes={{ root: classes.tableCell }}
                                                                >
                                                                    {
                                                                        showActions
                                                                            ? renderActions(row, navState, modifiable)
                                                                            : <div style={{ height: '100%', width: '100%' }} />
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })
                                            }
                                            {
                                                emptyRows > 0 &&
                                                <TableRow style={{ height: 52 * emptyRows }}>
                                                    <TableCell colSpan={6} classes={{ root: classes.tableCell }} />
                                                </TableRow>
                                            }
                                            {
                                                data[dataKey].length === 0 ?
                                                    <TableRow style={{ height: 52 * emptyRows }}>
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell >No hay datos</TableCell>
                                                        <TableCell />
                                                    </TableRow>
                                                    : null
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {showPagination ?
                                    <TablePagination
                                        rowsPerPageOptions={[ROWS_PER_PAGE]}
                                        component="div"
                                        count={data[`${dataKey}Count`]}
                                        rowsPerPage={ROWS_PER_PAGE}
                                        page={data.page}
                                        labelDisplayedRows={({ from, to, count }) => from + " al " + to + " de " + count}
                                        onChangePage={handleChangePage}
                                        classes={{ root: classes.pagination }}
                                    />
                                    : null}

                            </div>
                        )
                }
            </Paper>
            <SuccessToast
                open={successMessage !== ''}
                onClose={onCloseToast('successMessage')}
                text={successMessage}
            />
        </div>
    )
};

export default withStyles(styles)(TableComponent);
