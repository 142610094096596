export default [
    {
        id: 'firstName',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'lastName',
        label: 'Apellido',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'dni',
        label: 'DNI',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'dateFormatted',
        label: 'Fecha',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'hasAttended',
        label: 'Asistió',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    }
];
