import React from "react";
import {
    Button,
    CircularProgress,
    IconButton,
    withStyles,
    TextField,
    Card,
    Typography
} from "@material-ui/core";
import { Create as CreateIcon } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./MembershipTypeFormContainer";
import { FormLayout } from "../../common";

const MembershipTypeFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleImageChange,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewMembershipType,
        localImagePath,
        isEditable
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {isEditable ?
                <div>
                    {!editMode && (
                        <IconButton onClick={() => setEditMode(true)}>
                            <CreateIcon />
                        </IconButton>
                    )}
                </div>
                : <p>No es posible editarlo</p>}
        </div>
    );
    const ImageCard = () => (
        <div>
            <Typography variant="caption" gutterBottom className={classes.imageLabel}>
                Imagen
            </Typography>
            <Card elevation={3} className={classes.imageContainer}>
                <img alt="Imagen del Tipo de Abono" src={localImagePath ?? values.detailImage} className={classes.image} />
                {errors.detailImage ?
                    <Typography variant="h6" gutterBottom className={classes.imageLabelError}>
                        {errors.detailImage}
                    </Typography>
                    : null}
            </Card>
            {editMode &&
                <div className={classes.imageCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleImageChange}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.imageCardLabel} htmlFor="upload-file">
                        {submitting &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submitting}
                            className={classes.imageButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewMembershipType}
            dataKey="Tipo de Abono"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS['name']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />

            {editMode && <div className={classes.divider} />}
            <ImageCard />


        </FormLayout>
    );
};

export default withStyles(styles)(MembershipTypeFormComponent);