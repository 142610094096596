import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateMatch from './validator';
import MatchFormComponent from "./MatchFormComponent";
import * as Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/es';
import { getChangedValues, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    date: 'Fecha',
    instance: 'Instancia',
    rivalName: 'Rival',
    score: 'Resultado'
};

const INITIAL_STATE = {
    _id: '',
    date: moment(),
    instance: '',
    rivalName: '',
    score: '',
    tournament: ''
};

const MatchFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        handleDateChange,
        values,
        errors,
        setErrors,
        resetValues,
        mergeErrors,
        runValidations,
        handleChanges
    } = useFormValidation(INITIAL_STATE, values => validateMatch(values));
    const isNewMatch = objectIsEmpty(data);

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push(`/home/sportteams/${props.match.params.sportTeamId}/tournaments/${props.match.params.tournamentId}/matches`);

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);

            const { state } = props.location;

            const validKeys = {}

            //copy only valid fields
            stateKeys.forEach(k => {
                if (state.hasOwnProperty(k))
                    validKeys[k] = state[k]
            });
            if (Object.keys(validKeys).length === stateKeys.length) {

                setData(validKeys)
                resetValues(validKeys);
                setLoading(false);
                return;
            }
        }
        handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar Partido",
            text: `¿Estás seguro de que querés eliminar el partido '${values.instance}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`matches/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments/${props.match.params.tournamentId}/matches`,
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewMatch) {

                const { _id, tournament, score, ...body } = values

                post('matches', { tournament: props.match.params.tournamentId, ...(score && { score }), ...body })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments/${props.match.params.tournamentId}/matches`,
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                let changedValues = getChangedValues(data, values);
                if (changedValues.length === 0) {
                    props.history.push(`/home/sportteams/${props.match.params.sportTeamId}/tournaments/${props.match.params.tournamentId}/matches`);
                    return;
                }

                const body = {}

                changedValues.forEach(value => {
                    Object.assign(body, { [value]: values[value] });
                });

                patch(`matches/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: `/home/sportteams/${props.match.params.sportTeamId}/tournaments/${props.match.params.tournamentId}/matches`,
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <MatchFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDelete={handleDelete}
            handleDateChange={handleDateChange}
            loading={loading}
            submitting={submitting}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewMatch={isNewMatch}
            mergeErrors={mergeErrors}
            runValidations={runValidations}
            handleChanges={handleChanges}
        />
    );
};

export default MatchFormContainer;