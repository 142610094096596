import { ADDRESS, NAME_REGEX } from '../../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (!values.address) {
        errors.address = "Ingrese la dirección";
    } else if (!ADDRESS.test(values.address)) {
        errors.address = "La dirección tener el formato calle, municipio, localidad. Ejemplo: Balcarce 50, CABA, CABA"
    }

    return errors;
}