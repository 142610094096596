import React from "react";
import { IconButton, Checkbox, FormControlLabel, TextField, withStyles, Tooltip, Typography } from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, Filter1 } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./TournamentFormContainer";
import { FormLayout } from "../../common";
import { Link } from 'react-router-dom';

const TournamentFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleCheckBox,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewTournament,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {!isNewTournament &&
                <Link to={{
                    pathname: `/home/sportteams/${values.sportTeam}/tournaments/${values._id}/matches`,
                    state: values._id
                }}>
                    <Tooltip title="Ver Partidos">
                        <IconButton>
                            <Filter1 />
                        </IconButton>
                    </Tooltip>
                </Link>}
            {!editMode && (
                <IconButton onClick={() => setEditMode(true)}>
                    <CreateIcon />
                </IconButton>
            )}
            {!isNewTournament &&
                <IconButton onClick={handleDelete}>
                    <DeleteIcon />
                </IconButton>
            }
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewTournament}
            dataKey="Torneo"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS['name']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='url'
                error={errors.url !== undefined}
                helperText={errors.url}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                label={FIELDS['url']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={!editMode}
                        checked={values.hasFinished}
                        onChange={handleCheckBox}
                        name='hasFinished'
                    />
                }
                label={FIELDS['hasFinished']}
            />
            {editMode && <div className={classes.divider} />}
            <Typography variant="caption" className={classes.imageLabel}>
                Estadísticas
            </Typography>
            {
                errors.statistics &&
                <Typography variant="caption" color='error'>
                    {errors.statistics}
                </Typography>
            }
            {editMode && <div className={classes.divider} />}
            <TextField
                name='position'
                error={errors.position !== undefined}
                helperText={errors.position}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.position}
                label={FIELDS['position']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='numberOfTeams'
                error={errors.numberOfTeams !== undefined}
                helperText={errors.numberOfTeams}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.numberOfTeams}
                label={FIELDS['numberOfTeams']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='points'
                error={errors.points !== undefined}
                helperText={errors.points}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.points}
                label={FIELDS['points']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='pointsToFirst'
                error={errors.pointsToFirst !== undefined}
                helperText={errors.pointsToFirst}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.pointsToFirst}
                label={FIELDS['pointsToFirst']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <TextField
                name='pointsToLast'
                error={errors.pointsToLast !== undefined}
                helperText={errors.pointsToLast}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.pointsToLast}
                label={FIELDS['pointsToLast']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />
        </FormLayout>
    );
};

export default withStyles(styles)(TournamentFormComponent);