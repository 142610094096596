import {
    Button, Card, CircularProgress,
    IconButton, TextField, Typography, withStyles
} from "@material-ui/core";
import { Cancel, Clear, Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React from "react";
import { ApiAutocomplete, FormLayout } from "../../common";
import CustomAutocomplete from "../../common/CustomAutocomplete";
import { FIELDS } from "./FacilityTypeFormContainer";
import styles from "./styles";

const FacilityTypeFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        handleImageChange,
        submitting,
        loading,
        loadingBranches,
        data,
        values,
        editMode,
        setEditMode,
        isNewFacilityType,
        requiredMembershipTypeIsEditable,
        requiredMembeshipTypeEndAdornment,
        localImagePath,
        isEditable,
        branches
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {isEditable ?
                <div>
                    {!editMode && (
                        <IconButton onClick={() => setEditMode(true)}>
                            <CreateIcon />
                        </IconButton>
                    )}
                    {!isNewFacilityType &&
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </div>
                : <p>No es posible editarlo</p>}
        </div>
    );
    const ImageCard = () => (
        <div>
            <Typography variant="caption" gutterBottom className={classes.imageLabel}>
                Imagen
            </Typography>
            <Card elevation={3} className={classes.imageContainer}>
                <img alt="Imagen del Tipo de Instalación" src={localImagePath ?? values.detailImage} className={classes.image} />
                {errors.detailImage ?
                    <Typography variant="h6" gutterBottom className={classes.imageLabelError}>
                        {errors.detailImage}
                    </Typography>
                    : null}
            </Card>
            {editMode &&
                <div className={classes.imageCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleImageChange}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.imageCardLabel} htmlFor="upload-file">
                        {submitting &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submitting}
                            className={classes.imageButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </div>
    );

    const handleAutocompleteChange = (name) => (e, value) => {
        handleChange({ target: { name, value } })
    }

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewFacilityType}
            dataKey="Tipo de Instalación"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS.name}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />

            {editMode && <div className={classes.divider} />}
            <TextField
                name='shiftDuration'
                error={errors.shiftDuration !== undefined}
                helperText={errors.shiftDuration}
                onChange={handleChange}
                type="number"
                onBlur={handleBlur}
                value={values.shiftDuration}
                label={FIELDS.shiftDuration}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />

            {editMode && <div className={classes.divider} />}
            <CustomAutocomplete
                autoComplete
                id="branch"
                onChange={handleAutocompleteChange("branch")}
                loading={loadingBranches}
                loadingText="Buscando Sedes..."
                noOptionsText='No hay Sedes'
                options={branches}
                value={values.branch}
                getOptionSelected={(option, value) => option._id === value._id}
                getOptionLabel={(option) => option.name || ""}
                readOnly={!editMode}
                label={FIELDS.branch}
                error={errors.branch}
            />

            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.requiredMembershipType}
                handleChange={handleChange}
                label='name'
                name='requiredMembershipType'
                path='membershiptypes?name=like:'
                title={FIELDS.requiredMembershipType}
                value={values.requiredMembershipType}
                noOptionsText="No hay Tipos de Abono con ese nombre"
                disabled={!isNewFacilityType}
                dataField="membershipTypes"
                endAdornment={requiredMembershipTypeIsEditable && requiredMembeshipTypeEndAdornment && (<IconButton onClick={requiredMembeshipTypeEndAdornment.onClick} size="small">
                    <requiredMembeshipTypeEndAdornment.iconComponent fontSize="small" />
                </IconButton>)}
            />

            {editMode && <div className={classes.divider} />}
            <ImageCard />


        </FormLayout>
    );
};

export default withStyles(styles)(FacilityTypeFormComponent);