import React, { useState } from "react";
import {
    Button,
    IconButton,
    withStyles,
    TextField,
    MenuItem,
    Grid, TableContainer, Table, TableCell, Paper, TableHead, TableRow, Fab, TableBody,
    Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText
} from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import styles from "./styles";

const WEEK_DAYS = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

const WeekScheduler = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleChanges,
        values,
        FIELDS,
        runValidations,
        editMode,
        mergeErrors
    } = props;


    const [selectDay, setSelectDay] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const closeDialog = () => {
        setModalOpen(false)
        mergeErrors({
            newStartTime: undefined,
            newEndTime: undefined,
        })
        handleChanges({
            newStartTime: undefined,
            newEndTime: undefined,
        })
    }

    const saveNewSchedule = () => {

        const datas = ["newStartTime", "newEndTime"];

        runValidations()

        const hasError = datas.some(d => errors[d])

        if (hasError)
            return;

        const incomplete = datas.find(d => !values[d]);

        if (incomplete) {
            mergeErrors({
                [incomplete]: "Ingrese el campo"
            })
            return;
        }

        const remainingDays = values.schedules.filter(v => v.dayOfWeek !== selectDay)
        const day = values.schedules.find(v => v.dayOfWeek === selectDay) || { dayOfWeek: selectDay }

        handleChange({
            target: {
                name: "schedules",
                value: [
                    ...remainingDays,
                    {
                        ...day,
                        startTime: values.newStartTime,
                        endTime: values.newEndTime,
                    }
                ]
            }
        });

        handleChanges({
            newStartTime: undefined,
            newEndTime: undefined,
        })

        setModalOpen(false)
    }

    const removeInterval = (day) => {

        const remainingDays = values.schedules.filter(v => v.dayOfWeek !== day.dayOfWeek)

        const target = {
            name: "schedules",
            value: [
                ...remainingDays
            ]
        }

        handleChange({ target })
    }

    const day = values.schedules.find(v => v.dayOfWeek === selectDay)

    const TBody = () => {
        if (!day)
            return (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">No hay horarios para este día</TableCell>
                </TableRow>
            );

        return (
            <TableBody>
                <TableRow>
                    <TableCell align="center">{day.startTime}hs</TableCell>
                    <TableCell align="center">{day.endTime}hs</TableCell>
                    {editMode && (<TableCell align="center"><IconButton onClick={() => removeInterval(day)} aria-label="delete"><DeleteIcon /></IconButton></TableCell>)}
                </TableRow>
            </TableBody>
        );
    }

    return (
        <div>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <TextField
                    select
                    name='schedules'
                    error={errors.schedules !== undefined}
                    helperText={errors.schedules}
                    onChange={(event) => { setSelectDay(event.target.value) }}
                    label={FIELDS['schedules']}
                    className={classes.textField}
                    value={selectDay}
                    InputProps={{
                        disableUnderline: false
                    }}
                >
                    {WEEK_DAYS.map((day, dayNum) => (
                        <MenuItem key={dayNum} value={dayNum}>
                            {day}
                        </MenuItem>
                    ))}
                </TextField>
                {editMode && !day ?
                    <Fab color="primary" size="small" variant="extended" onClick={() => { setModalOpen(true) }}>
                        <AddIcon /> Agregar horario
                    </Fab>
                    : null
                }

            </Grid>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Hora de inicio</TableCell>
                            <TableCell align="center">Hora de finalización</TableCell>
                            {editMode && (<TableCell align="center"></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TBody />
                </Table>
            </TableContainer>

            <Dialog open={modalOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nuevo Horario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Estás estableciendo el horario de la actividad para el día {WEEK_DAYS[selectDay]}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        name='newStartTime'
                        error={errors.newStartTime !== undefined}
                        helperText={errors.newStartTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newStartTime}
                        label="Hora de inicio"
                        className={classes.textField}
                        fullWidth
                    />

                    <TextField
                        name='newEndTime'
                        error={errors.newEndTime !== undefined}
                        helperText={errors.newEndTime}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.newEndTime}
                        label="Hora de finalización"
                        className={classes.textField}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={saveNewSchedule} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(WeekScheduler);