export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'hasFinishedMessage',
        label: 'Finalizado',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'briefStatistics',
        label: 'Estadísticas',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
