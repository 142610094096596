import React from 'react';
import {
    CircularProgress,
    Grid,
    LinearProgress,
    withStyles
} from "@material-ui/core";
import styles from "./styles";

const RedirectOnboardingComponent = ({
    classes,
    clubData
     }) => {
        console.log(clubData)
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.gridOnboarding}
        >
            <div className={classes.formOnboarding}>
                <img
                    src={clubData.logoPath}
                    width={100}
                    height={115}
                    alt="Logo"
                    style={{ margin: '0 auto' }}
                />
                <div style={{marginTop: '80px'}} >
                <CircularProgress  />
                </div>
            </div>
        </Grid>
    )
};

// export default OnboardingComponent;
export default withStyles(styles)(RedirectOnboardingComponent);