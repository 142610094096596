import {
    CssBaseline, Drawer, Hidden, List, ListItem, ListItemIcon,
    ListItemText, ListSubheader, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withTheme from "@material-ui/core/styles/withTheme";
import { Assessment, Description, Event, Flag as FlagIcon, Group, Help, HomeWork, Label, LocalOffer, OutdoorGrill, Payment, Person, PersonAdd, Phonelink, Place, Security, Sports, SportsSoccer } from '@material-ui/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { handleResponse, post } from '../../utils/network';
import { getCookie } from '../../utils/utils';

const DRAWER_WIDTH = 260;

export const drawerOptions = [
    {
        label: 'Usuarios',
        value: 0,
        path: '/home/users',
        icon: Group,
        permissionNeeded: 'manageUsers',
        section: 1,
    },
    {
        label: 'Roles',
        value: 1,
        path: '/home/roles',
        icon: Security,
        permissionNeeded: 'manageRoles',
        section: 1,
    },
    {
        label: 'Perfil',
        value: 2,
        path: '/home/profile',
        icon: Person,
        permissionNeeded: 'none',
        section: 0,
    },
    {
        label: 'Sesiones',
        value: 3,
        path: '/home/sessions',
        icon: Phonelink,
        permissionNeeded: 'none',
        section: 0,
    },
    {
        label: 'Tópicos',
        value: 4,
        path: '/home/topics',
        icon: Label,
        permissionNeeded: 'manageTopics',
        section: 2,
    },
    {
        label: 'Novedades',
        value: 5,
        path: '/home/news',
        icon: Description,
        permissionNeeded: 'manageNews',
        section: 2,
    },
    {
        label: 'Sedes',
        value: 6,
        path: '/home/branches',
        icon: Place,
        permissionNeeded: 'manageBranches',
        section: 2,
    },
    {
        label: 'Eventos',
        value: 7,
        path: '/home/events',
        icon: Event,
        permissionNeeded: 'manageEvents',
        section: 2
    },
    {
        label: 'Tipos de Abono',
        value: 8,
        path: '/home/membershipTypes',
        icon: LocalOffer,
        permissionNeeded: 'manageMemberships',
        section: 2,
    },
    {
        label: 'Abonos',
        value: 9,
        path: '/home/memberships',
        icon: Payment,
        permissionNeeded: 'manageMemberships',
        section: 2,
    },
    {
        label: 'Preguntas Frecuentes',
        value: 10,
        path: '/home/faqs',
        icon: Help,
        permissionNeeded: 'manageFAQs',
        section: 2,
    },
    {
        label: 'Tipos de Instalación',
        value: 11,
        path: '/home/facilityTypes',
        icon: HomeWork,
        permissionNeeded: 'manageFacilities',
        section: 2,
    },
    {
        label: 'Instalaciones',
        value: 12,
        path: '/home/facilities',
        icon: OutdoorGrill,
        permissionNeeded: 'manageFacilities',
        section: 2,
    },
    {
        label: 'Disciplinas',
        value: 13,
        path: '/home/disciplines',
        icon: Sports,
        permissionNeeded: 'manageDisciplines',
        section: 2,
    },
    {
        label: 'Actividades',
        value: 14,
        path: '/home/activities',
        icon: SportsSoccer,
        permissionNeeded: 'manageActivities',
        section: 2,
    },
    {
        label: 'Seleccionados',
        value: 15,
        path: '/home/sportteams',
        icon: FlagIcon,
        permissionNeeded: 'manageSportTeams',
        section: 2,
    },
    {
        label: 'Invitados',
        value: 16,
        path: '/home/invitees',
        icon: PersonAdd,
        permissionNeeded: 'listAndConfirmInvitees',
        section: 2,
    },
    // {
    //     label: 'Reportes',
    //     value: 16,
    //     path: '/home/reports',
    //     icon: Assessment,
    //     permissionNeeded: 'viewReports',
    //     section: 2,
    // }
];

const drawerSections = [
    {
        value: 0,
        label: 'Mi cuenta'
    },
    {
        value: 1,
        label: 'Administración'
    },
    {
        value: 2,
        label: 'Tu club'
    }
];

const styles = theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        [theme.breakpoints.up('sm')]: {
            width: DRAWER_WIDTH,
            flexShrink: 0,
        },
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%",
            overflow: 'hidden',
        }
    },
    container: {
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: "#EDEDED",
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
        }
    },
    drawerPaper: {
        zIndex: 1000,
        width: DRAWER_WIDTH,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
    },
    listSubheader: {
        lineHeight: 0,
        paddingBottom: 15,
        fontWeight: 600,
        color: '#9e9c9c',
        fontSize: 12
    },
    itemText: {
        fontWeight: 600,
        fontSize: 14,
    }
});

const getDrawerOptionValueFromPath = (location) => {
    const currentDrawerOption = drawerOptions.find(option => location.pathname.includes(option.path));
    return currentDrawerOption?.value || 0;
}


class DrawerLeft extends Component {
    constructor(props) {
        super(props);
        this.state = {
            club: {},
            value: getDrawerOptionValueFromPath(this.props.history.location),
            smallScreen: false,
            routeOptions: [],
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
        this.resize();
        this.setState({ routeOptions: JSON.parse(getCookie('routeOptions')) });

        const { club } = this.props;
        if (club) {
            this.setState({ club });
        } else {
            post('auth/logout', "")
                .then(res => {
                    return handleResponse(res, this.props);
                })
            this.props.history.push('/onboarding');
        }

        this.changeLocationListener = this.props.history.listen(location =>
            this.setState({ value: getDrawerOptionValueFromPath(location) })
        );
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        this.changeLocationListener();
    }

    resize() {
        this.setState({ smallScreen: window.innerHeight <= 735 });
    };

    handleChange = (index) => {
        this.setState({ value: index }, () => this.props.handleDrawerToggle(false));
    };

    focusItem = (valor) => {
        if (this.state.value === valor)
            return {
                borderLeft: `4px solid ${this.props.theme.palette.primary.main}`,
                color: this.props.theme.palette.primary.main,
                marginTop: 10,
            };
        else
            return { color: 'grey', marginTop: 10 };
    };

    render() {
        const { classes, mobileOpen, handleDrawerToggle } = this.props;
        const { smallScreen, value, routeOptions } = this.state;
        const filteredDrawerOptions = drawerOptions.filter(op => routeOptions.some(r_op => r_op.value === op.value));

        const CustomListItem = ({ option }) => (
            <ListItem
                key={option.value}
                button
                style={this.focusItem(option.value)}
                onClick={() => this.handleChange(option.value)}
                component={Link}
                to={option.path}
            >
                <ListItemIcon>
                    {React.createElement(
                        option.icon,
                        {
                            style: {
                                color: value === option.value ? this.props.theme.palette.primary.main : 'grey',
                                marginLeft: 10
                            }
                        })
                    }
                </ListItemIcon>
                <ListItemText primary={option.label} classes={{ primary: classes.itemText }} />
            </ListItem>
        );

        const drawer = (
            <>
                <div style={{ padding: smallScreen ? 10 : 20 }}>
                    <img
                        src={`/assets/${this.state.club.crestImagePath}`}
                        width={DRAWER_WIDTH - 50}
                        height={200}
                        alt="Logo"
                    />
                </div>
                <List>
                    {
                        drawerSections.map((section, index) => {
                            const sectionOptions = filteredDrawerOptions.filter(op => op.section === section.value);
                            return sectionOptions.length > 0 && (
                                <div key={section.value}>
                                    <ListSubheader
                                        className={classes.listSubheader}
                                        disableSticky
                                        style={{
                                            borderTop: index !== 0 ? '1px solid #ececec' : 'unset',
                                            marginTop: index !== 0 ? 15 : 0,
                                            paddingTop: index !== 0 ? 24 : 0,
                                        }}
                                    >
                                        {section.label}
                                    </ListSubheader>
                                    {sectionOptions.map(option => <CustomListItem option={option} key={option.value} />)}
                                </div>
                            )
                        })
                    }
                </List>
                {
                    !smallScreen &&
                    <div style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        flexGrow: 1,
                        paddingBottom: 30
                    }}>
                        <div style={{ display: 'flex', color: 'grey', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography>Powered By</Typography>
                            <Typography style={{ fontWeight: 600 }}>Clubstar</Typography>
                        </div>
                    </div>
                }
            </>
        );

        return (
            <div className={classes.root}>
                <CssBaseline />
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor='left'
                        open={mobileOpen}
                        onClose={() => handleDrawerToggle()}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        open
                        anchor="left"
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    club: state
});

export default withTheme(withStyles(styles)(connect(mapStateToProps)(DrawerLeft)));
