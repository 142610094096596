import React, { Component } from 'react';
import { withTheme, withStyles, Popover } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { SuccessToast } from '../../common';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { genericOnRangeChange } from '../../../utils/utils';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from 'moment';
import ReservationFormContainer from '../ReservationForm/ReservationFormContainer';
import { getFreeShifts, getOpenInterval, isFacilityOpenOnDate } from '../utils'
import { CALENDAR_MESSAGES } from '../../common/CalendarMessages';
moment.locale('es', { week: { dow: 0 } });
const localizer = momentLocalizer(moment);

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        marginBottom: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    dateInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    textField: {
        marginTop: 13,
        marginLeft: "15px"
    },
    dateField: {
        marginTop: 13,
        marginLeft: "15px",
        marginBottom: "26px"
    },
    loadMoreButton: {
        width: "100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign: "center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'La reserva ha sido creada correctamente',
    deleted: 'La reserva ha sido eliminada correctamente'
};

const CALENDAR_STEP = 15;

const ALLOWED_VIEWS = [Views.MONTH, Views.WEEK, Views.DAY];

class ReservationContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facility: null,
            page: 0,
            maxPageFetched: 0,
            editing: false,
            reservations: [],
            reservationsCount: 0,
            freeSlots: [],
            loading: true,
            updating: false,
            successMessage: '',
            currentDate: moment(),
            date: moment().toDate(),
            view: Views.WEEK,
            anchorEl: null,
            selectedReservation: {},
            start: null
        }
    }

    componentDidMount() {
        const { state } = this.props.location;
        if (state) {
            this.setState({ facility: state });
        }
        else {
            this.props.history.push('/home/facilities');
        }
        const msgKey = state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }

        this.onRangeChange(this.state.currentDate.clone().startOf('week').toISOString(), this.state.currentDate.clone().endOf('week').toISOString());
    };

    getFetchUrl = (startDate, endDate) => {
        const queryParams = ['app=bof', `page=${this.state.page}`, `from=${startDate}`, `to=${endDate}`];
        return `facilities/${this.state.facility._id}/reservedShifts?${queryParams.join('&')}`
    }

    getReservations = (start, end) => {
        const fetchUrl = this.getFetchUrl(start, end);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                this.setState((state) => {
                    return {
                        page: state.page + 1,
                        reservations: [...state.reservations, ...parsed.message.data.map((reservation) => ({
                            ...reservation,
                            start: new Date(reservation.start),
                            end: new Date(reservation.end)
                        }))],
                        ...(parsed.message.count && {
                            reservationsCount: parsed.message.count,
                        }),
                        loading: false,
                        updating: false
                    }
                },
                    () => {
                        if (this.state.reservations.length < this.state.reservationsCount) {
                            this.getReservations(start, end)
                        } else {
                            const shifts = this.state.currentDate.isSameOrBefore(end, 'd') ? getFreeShifts(this.state.facility, this.state.reservations, start, end, this.state.currentDate) : [];
                            this.setState((state) => ({ reservations: [...state.reservations, ...shifts] }));
                        }
                    }
                )
            })
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    onRangeChange = (start, end) => {
        this.setState({
            page: 0,
            reservations: [],
            reservationsCount: 0
        }, () => this.getReservations(start, end))
    };

    setMessage = (msgKey) => {
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    }

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = id => id ? this.props.history.push('/home/reservations/' + id) : this.props.history.push({
        pathname: '/home/reservations/new',
        state: { facilityId: this.state.facility._id }
    });

    handleDelete = ({ _id, start, end, price }) => {
        this.handleClose();
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar reserva",
            text: "¿Estas seguro de que queres eliminar la reserva?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar reserva',
            confirmButtonText: 'Sí, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`reservations/${_id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result => {

                                this.setState(
                                    (prevState) => ({
                                        reservations: [...prevState.reservations.filter(reservation => reservation._id !== _id), { start, end, price, free: true }],
                                        reservationsCount: prevState.reservationsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                )
                                this.handleClose();
                            })
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    onSelectReservation = (reservation, event) => {
        this.setState({
            selectedReservation: reservation,
            anchorEl: {
                left: event.clientX,
                top: event.clientY
            }
        });
    }
    onSelectSlot = (slotInfo) => {
        if (this.state.view === 'month') {
            this.onDrillDown(slotInfo.start, 'day')
        }
        else if (moment().isAfter(slotInfo.start)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '¡No podés hacer una reserva pasada!'
            })
        }
        else if (!getOpenInterval(slotInfo.start, this.state.facility, this.state.facility.type.shiftDuration))
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: '¡La instalación no está abierta en ese horario!'
            })
    };

    onDrillDown = (date, view) => {
        if (view === 'day') {
            if (isFacilityOpenOnDate(this.state.facility, date)) {
                this.setState({ date, view });
                genericOnRangeChange(this.onRangeChange)([date]);
            }
            else
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: '¡La instalación no está abierta ese día!'
                })
        }
    }

    onViewChange = (view) => {
        if (view === Views.MONTH)
            this.setState({ page: 0, reservations: [], reservationsCount: 0 })
        this.setState({ view });
    }


    customSlotPropGetter = date => {
        if (this.state.facility && !getOpenInterval(date, this.state.facility, CALENDAR_STEP)) {
            return {
                style: {
                    backgroundColor: '#D3D3D3',
                }
            }
        }
        else if (moment().isAfter(date)) {
            return {
                style: {
                    backgroundColor: '#EDEDED'
                }
            }
        } else return {}
    }

    customDayPropGetter = date => {
        if (!moment().isSame(date, 'd') && !isFacilityOpenOnDate(this.state.facility, date))
            return {
                style: {
                    backgroundColor: '#D3D3D3'
                }
            }
        else if (moment().isBefore(date)) {
            return {
                style: {
                    backgroundColor: '#FFFFFF'
                }
            }
        }
    }

    customEventPropGetter = (event) => {
        if (event.free) {
            return {
                style: {
                    backgroundColor: '#00BA07',
                    fontStyle: 'italic'

                }
            }
        } else return {
            style: {
                backgroundColor: '#CC2200'
            }
        }
    }

    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, selectedReservation: {} });
    };

    addReservation = (reservation) => {
        const reservationStart = moment(reservation.start);
        const index = this.state.reservations.findIndex(res => reservationStart.isSame(res.start, 'minutes'));
        this.setState(prevState => ({
            reservations: prevState.reservations.toSpliced(index, 1, reservation),
            reservationsCount: prevState.reservationsCount + 1
        }))
    }

    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <>
                <Calendar
                    selectable
                    date={this.state.date}
                    onNavigate={(date) => this.setState({ date })}
                    view={this.state.view}
                    onView={this.onViewChange}
                    getNow={() => this.state.currentDate.toDate()}
                    localizer={localizer}
                    events={this.state.reservations}
                    titleAccessor={(reservation) => reservation.free ? 'Turno libre' : `${reservation.reservor.firstName} ${reservation.reservor.lastName}`}
                    allDayAccessor={() => false}
                    messages={CALENDAR_MESSAGES}
                    onRangeChange={genericOnRangeChange(this.onRangeChange)}
                    onSelectEvent={this.onSelectReservation}
                    onSelectSlot={this.onSelectSlot}
                    onSelecting={() => false}
                    onDrillDown={this.onDrillDown}
                    eventPropGetter={this.customEventPropGetter}
                    slotPropGetter={this.customSlotPropGetter}
                    dayPropGetter={this.customDayPropGetter}
                    style={{ height: 800 }}
                    step={CALENDAR_STEP}
                    timeslots={4}
                    views={ALLOWED_VIEWS}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorPosition={this.state.anchorEl}
                    anchorReference='anchorPosition'
                    onClose={this.handleClose}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <ReservationFormContainer
                        facility={this.state.facility}
                        reservation={this.state.selectedReservation}
                        handleClose={this.handleClose}
                        handleDelete={this.handleDelete}
                        setMessage={this.setMessage}
                        addReservation={this.addReservation}
                        start={this.state.start}

                    />
                </Popover>
                <SuccessToast
                    open={this.state.successMessage !== ''}
                    onClose={this.onCloseToast('successMessage')}
                    text={this.state.successMessage}
                />
            </>
        )
    }
}

export default withTheme(withStyles(styles)(ReservationContainer));
