import { NAME_REGEX } from '../../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.name) {
        errors.name = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.name)) {
        errors.name = "El nombre debe ser un nombre válido"
    }

    if (typeof values.detailImage == "object" && !values.detailImage.type.includes("image/")) {
        errors.detailImage = "Debe cargar una imagen"
    }

    return errors;
}