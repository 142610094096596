import React, { Component } from 'react';
import { post, handleResponse, swal500 } from '../../utils/network';
import { getCookie, setCookie } from "../../utils/utils";
import LoginComponent from "./LoginComponent";
import { drawerOptions, AllPermissions } from "../../components/common";
import { connect } from 'react-redux';
import { SET_CLUB } from '../../utils/state/store';
import { Redirect } from 'react-router-dom';

class LoginContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            loading: false,
            erroremail: '',
            errorpassword: '',
            passwordResetOpen: false,
            successMessage: ''
        };
    }

    componentDidMount() {
        if (getCookie("isLogged")) {
            this.props.history.push('/home');
        }
    };

    errorReset = () => {
        this.setState({
            erroremail: '',
            errorpassword: ''
        });
    };

    emptyInputs = () => {
        const { email, password } = this.state;
        if (email === '') {
            this.setState({ erroremail: 'Ingrese el correo electrónico' });
        }
        if (password === '') {
            this.setState({ errorpassword: 'Ingrese la contraseña' });
        }
        this.setState({ loading: false });
    };

    handleChange = e => {
        const { name, value } = e.target;
        this.errorReset();
        this.setState({
            [name]: value
        });
    };


    handleClose = () => {
        this.props.resetClub();
        this.props.history.push('/onboarding');
    }

    handle401 = err => {
        err.text().then(() => {
            this.setState({
                errorpassword: "Correo electrónico o contraseña incorrectos",
                erroremail: "Correo electrónico o contraseña incorrectos"
            });
        });
        return Promise.reject(401);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        const { club: { shortName: clubShortName } } = this.props;
        if (!(email === '' || password === '')) {
            this.setState(
                { loading: true },
                () => {
                    const body = {
                        email,
                        password,
                        clubShortName
                    };

                    post('auth/login', body, false)
                        .then(res => {
                            return handleResponse(res, this.props, [
                                { status: 401, method: this.handle401 },
                                { status: 404, method: () => Promise.reject(404) },
                                { status: 400, method: () => Promise.reject(400) },
                            ]
                            );
                        })
                        .then(data => {
                            const name = data.message.firstName ? data.message.firstName.split(" ")[0] : "Usuario";
                            this.setState(
                                { loading: false },
                                () => {
                                    setCookie("isLogged", true);
                                    setCookie("userId", data.message.userId);
                                    setCookie("name", name);
                                    this.redirectUser(data.message.permissions);
                                });
                        })
                        .catch(err => {
                            this.setState({ loading: false });
                            if (err === 404 || err === 400) {
                                this.setState({
                                    errorpassword: "Usuario o contraseña incorrectos",
                                    erroremail: "Usuario o contraseña incorrectos"
                                });
                            } else {
                                swal500(err);
                            }
                        });
                });
        } else {
            this.emptyInputs();
        }
    };

    redirectUser = (userPermissions) => {
        const routeOptions = drawerOptions
            .filter(op => op.permissionNeeded === 'none' ||
                userPermissions.some(us_p => us_p === AllPermissions.all) ||
                userPermissions.some(us_p => us_p === AllPermissions[op.permissionNeeded])
            )
            .map(option => ({ path: option.path, value: option.value }));
        setCookie("routeOptions", JSON.stringify(routeOptions));
        this.props.history.push(routeOptions[0].path);
    };

    handlePasswordReset = (value) => this.setState({ passwordResetOpen: value });

    passwordResetSuccess = () => this.setState({ successMessage: "Te enviamos un mail para que puedas recuperar tu contraseña." });

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    render() {
        return (this.props.club ?
            <LoginComponent
                successMessage={this.state.successMessage}
                passwordResetOpen={this.state.passwordResetOpen}
                handlePasswordReset={this.handlePasswordRFeset}
                passwordResetSuccess={this.passwordResetSuccess}
                onCloseToast={this.onCloseToast}
                club={this.props.club}
                data={this.state}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                handleClose={this.handleClose}
            />
            : <Redirect to="/onboarding" />)
    }
}

const mapStateToProps = state => ({
    club: state
});

const mapDispatchToProps = dispatch => ({ resetClub: () => dispatch({ type: SET_CLUB, club: null }) });


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);