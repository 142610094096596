export default [
    {
        id: 'typeName',
        label: 'Tipo',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'description',
        label: 'Descripción',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'enabledLabel',
        label: 'Habilitada',
        minWidth: 50,
        hidden: {
            xsDown: true
        }
    },
];
