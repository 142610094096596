import React from 'react'
import { Card, TextField, Grid, Fab, IconButton, Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import FAQItem from './FAQItem';
import { SuccessToast, Loading } from '../common';
import { get, handleResponse, swal500 } from '../../utils/network';

const styles = theme => ({
    header: {
        padding: "5px 5px 5px 5px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontSize: "20px",
        color: "#808080"
    },
    searchInput: {
        height: "100%",
        display: "inline-flex",
        flexDirection: "row",
        marginBottom: "6px"
    },
    searchButton: {
        marginTop: "10px"
    },
    bodyContainer: {
        marginTop: "10px"
    },
    loadMoreButton: {
        width: "100%",
        display: "inline-flex",
        marginTop: "30px",
        justifyContent: "center"
    },
    noResults: {
        textAlign: "center",
        fontSize: "20px",
        marginTop: "30px",
        color: theme.palette.secondary.main
    }
});

const MESSAGES = {
    created: 'La FAQ ha sido creada correctamente',
    edited: 'La FAQ ha sido editada correctamente',
    deleted: 'La FAQ ha sido eliminada correctamente'
};

class FAQsContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            successMessage: '',
            loading: true,
            showSeeMore: false,
            questionFilter: '',
            onlyMineFilter: false,
            faqs: []
        }
    }

    componentDidMount() {
        this.getFAQs()
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }

    }

    getFetchUrl = (page) => {
        const filters = [];
        if (this.state.questionFilter !== '') {
            filters.push(`question=like:${this.state.questionFilter}`);
        }
        if (this.state.onlyMineFilter)
            filters.push("onlyMine=eqb:true")
        const queryParams = ['app=bof', `page=${page}`, ...filters];
        return `faqs?${queryParams.join('&')}`
    }

    getFAQs = () => {
        const fetchUrl = this.getFetchUrl(0);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed =>
                this.setState({
                    faqs: parsed.message.data,
                    faqsCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false,
                    showSeeMore: parsed.message.count > parsed.message.data.length
                })
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                const fetchUrl = this.getFetchUrl(page);
                get(fetchUrl)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            faqs: prevState.faqs.concat(parsed.message.data),
                            maxPageFetched: page,
                            loading: false,
                            updating: false,
                            showSeeMore: (prevState.faqs.length + parsed.message.data.length) < prevState.faqsCount
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleFilter = () => {
        this.getFAQs();
    }

    onQuestionSearchChange = ({ target }) => {
        this.setState({ questionFilter: target.value });
    }

    handleCheckBox = ({ target }) => {
        this.setState({ onlyMineFilter: target.checked });
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, question, answer, author) => id ? this.props.history.push({
        pathname: '/home/faqs/' + id,
        state: { id, question, answer, author },
    })
        :
        this.props.history.push('/home/faqs/new');

    keyPress = (e) => {
        if (e.keyCode === 13)
            this.handleFilter()

    }

    render() {
        const { classes } = this.props;
        return (
            this.state.loading ?
                <Loading />
                :
                <div>
                    <Card elevation={3} className={classes.header}>
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justify="space-between"
                        >
                            <div>
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                >
                                    <div style={{ marginRight: "4em" }}>
                                        <h2>Preguntas Frecuentes</h2>
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.onlyMineFilter}
                                                    onChange={this.handleCheckBox}
                                                />
                                            }
                                            label='Solo mías'
                                        />
                                        <div className={classes.searchInput} >
                                            <TextField
                                                label="Buscar por pregunta"
                                                value={this.state.questionFilter}
                                                onKeyDown={this.keyPress}
                                                onChange={this.onQuestionSearchChange}
                                            />
                                            <div className={classes.searchButton}>
                                                <IconButton onClick={this.handleFilter} color="secondary">
                                                    <SearchIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <Fab
                                color="primary"
                                size="medium"
                                onClick={() => this.handleOpenForm()}
                            ><AddIcon /></Fab>
                        </Grid>
                    </Card>

                    {
                        (this.state.faqs.length > 0) ?
                            <Grid
                                container
                                justify="space-between"
                                className={classes.bodyContainer}
                            >
                                {this.state.faqs.map(faq => (
                                    <FAQItem key={faq._id} id={faq._id} question={faq.question} answer={faq.answer} author={faq.author} onClick={this.handleOpenForm} />
                                ))}
                            </Grid>

                            :

                            <p className={classes.noResults}>No hay Preguntas Frecuentes</p>
                    }

                    {
                        this.state.showSeeMore ?
                            <div className={classes.loadMoreButton}>
                                <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={() => this.showMore(this.state.maxPageFetched + 1)}>Cargar más</Button>
                            </div>
                            :
                            null
                    }


                    <SuccessToast
                        open={this.state.successMessage !== ''}
                        onClose={this.onCloseToast('successMessage')}
                        text={this.state.successMessage}
                    />
                </div >
        );
    }
}

export default withTheme(withStyles(styles)(FAQsContainer))