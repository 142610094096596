import React from 'react'
import {Card, Fab } from '@material-ui/core'
import { withTheme, withStyles } from '@material-ui/core/styles'


const styles = theme => ({
    card: {
        padding: "5px 15px 5px 15px",
        display:"inline-flex",
        flexDirection: "row",
        alignItems: "center",
        width: "300px",
        marginTop: "30px"
    },
    cardName: {
        marginLeft: "15px",
        color: "#6E6E6E",
        fontWeight: "normal",
        fontSize: "19px"
    }
});

class TopicItem extends React.Component
{

    onClick = () => {
        this.props.onClick(this.props.id, this.props.title, this.props.color)
    }

    render()
    {
        const { classes } = this.props;
        return (
            <Card className={classes.card} elevation={3} onClick={this.onClick}>
                <Fab disabled size="small" style={{backgroundColor:this.props.color}} />
                <p className={classes.cardName}>{this.props.title}</p>
            </Card>
        );
    }
}

export default withTheme(withStyles(styles)(TopicItem))