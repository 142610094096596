import React from "react";
import {
    IconButton,
    withStyles,
    TextField,
    Tooltip
} from "@material-ui/core";
import { Create as CreateIcon, Delete as DeleteIcon, EmojiEvents } from '@material-ui/icons';
import styles from "./styles";
import { FIELDS } from "./SportTeamFormContainer";
import { ApiAutocomplete, FormLayout } from "../../common";
import PlayerPicker from "../PlayerPicker/PlayerPicker";
import { Link } from 'react-router-dom';

const SportTeamFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        submitting,
        loading,
        values,
        editMode,
        setEditMode,
        isNewSportTeam,
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            <div>
                {!isNewSportTeam &&
                    <Link to={{
                        pathname: `/home/sportteams/${values._id}/tournaments`,
                        state: values._id
                    }}>
                        <Tooltip title="Ver Torneos">
                            <IconButton>
                                <EmojiEvents />
                            </IconButton>
                        </Tooltip>
                    </Link>}
                {!editMode && (
                    <IconButton onClick={() => setEditMode(true)}>
                        <CreateIcon />
                    </IconButton>
                )}
                {!isNewSportTeam &&
                    <IconButton onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                }
            </div>
        </div>
    );

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewSportTeam}
            dataKey="Seleccionado"
        >
            <TextField
                name='name'
                error={errors.name !== undefined}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                label={FIELDS['name']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode,
                    disableUnderline: !editMode
                }}
            />
            {editMode && <div className={classes.divider} />}
            <ApiAutocomplete
                error={errors.discipline}
                handleChange={handleChange}
                label='name'
                name='discipline'
                path='disciplines?competitive=eqb:true&name=like:'
                title={FIELDS["discipline"]}
                value={values.discipline}
                noOptionsText="No hay disciplinas con ese nombre"
                disabled={!editMode || submitting}
                dataField="disciplines"
            />
            {editMode && <div className={classes.divider} />}
            <PlayerPicker {...props} FIELDS={FIELDS} classes={null} />

        </FormLayout>
    );
};

export default withStyles(styles)(SportTeamFormComponent);