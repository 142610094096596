import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del, get } from '../../../utils/network';
import { useFormValidation, usePermissionSelector } from "../../common";
import validateUser from './validator';
import UserFormComponent from "./UserFormComponent";
import * as Swal from "sweetalert2";
import moment from 'moment';
import 'moment/locale/es';
import { getCookie, logout, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    firstName: "Nombre",
    lastName: "Apellido",
    memberNumber: "Número de Socio",
    birthdate: "Fecha de Nacimiento",
    phoneNumber: "Número de Celular",
    email: "Correo electrónico",
    password: "Contraseña",
    role: "Rol"
};

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    memberNumber: '',
    phoneNumber: '',
    birthdate: null,
    email: '',
    password: '',
    role: null
};

const UserFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [allPermissions, setAllPermissions] = useState([]);
    const [loadingRoles, setLoadingRoles] = useState(true);
    const [allRoles, setAllRoles] = useState([]);
    const isNewUser = objectIsEmpty(data);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues,
        handleDateChange
    } = useFormValidation(INITIAL_STATE, values => validateUser(values, getPermissionsList, isNewUser));

    const {
        handleChangePermission,
        handleChangeCategory,
        permissions,
        setPermissions,
        getPermissionsList,
        permissionsDidChange
    } = usePermissionSelector();

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(values).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });
        if (data.role?._id !== values.role?._id) {
            delete changedValues.role;
        }
        if (permissionsDidChange(data.permissions) || changedValues.some(v => v === "role")) {
            changedValues.push('permissions');
        }
        return changedValues.filter(v => v !== 'created' && v !== 'rolePermissions');
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    useEffect(() => {
        const getPermissions = () => {
            setLoadingPermissions(true);
            get('permissions')
                .then(res => handleResponse(res, props))
                .then(res => {
                    setLoadingPermissions(false);
                    setAllPermissions(res.message);
                })
                .catch(e => {
                    setLoadingPermissions(false);
                    swal500(e);
                });
        };

        const getRoles = () => {
            setLoadingRoles(true);
            get('roles')
                .then(res => handleResponse(res, props))
                .then(res => {
                    setLoadingRoles(false);
                    setAllRoles(res.message.data);
                })
                .catch(e => {
                    setLoadingRoles(false);
                    swal500(e);
                });
        };

        const getUser = () => {
            setLoading(true);
            get('users/' + props.match.params.id)
                .then(res => handleResponse(res, props))
                .then(parsed => {
                    if (parsed.error) props.history.push('/home/users');
                    else {
                        parsed.message.birthdate = parsed.message.birthdate ? moment(parsed.message.birthdate) : moment();
                        setData(parsed.message);
                        resetValues(parsed.message);
                        setLoading(false);
                    }
                });
        };

        getPermissions();
        getRoles();
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
        } else {
            getUser();
        }
    }, [props, resetValues]);

    useEffect(() => {
        const buildPermissions = () => {
            return allPermissions
                .map(permission => {
                    if (!permission.category) return { ...permission, category: "Otros" };
                    return permission;
                })
                .map(permission => ({
                    ...permission,
                    permissions: permission.permissions.map(p => ({
                        ...p,
                        checked: values.role?.permissions?.includes(p._id) || data.permissions?.map(p => p._id).includes(p._id),
                        disabled: values.role?.permissions?.includes(p._id)
                    }))
                }));
        };

        if (allPermissions.length) {
            setPermissions(buildPermissions());
        }
    }, [values.role, allPermissions, data.permissions, setPermissions]);

    const handleClose = () => props.history.push('/home/users');

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar usuario",
            text: `¿Estas seguro de que queres eliminar al usuario ${values.firstName} ${values.lastName}?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar usuario',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`users/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result => {
                        const userId = getCookie('userId');
                        if (props.match.params.id === userId) {
                            logout();
                            props.history.push('/Login');
                        }
                        else
                            props.history.push({
                                pathname: '/home/users',
                                state: { msg: 'deleted' },
                            })
                    })
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewUser) {
                post('users', {
                    password: values.password,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    memberNumber: values.memberNumber,
                    birthdate: values.birthdate,
                    ...(values.phoneNumber.length && { phoneNumber: values.phoneNumber }),
                    ...(values.role && { role: values.role._id }),
                    permissions: getPermissionsList
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/users',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/users');
                    return;
                }
                changedValues.forEach(value => {
                    if (value === 'role') {
                        if (values.role?._id) Object.assign(body, { role: values.role._id });
                        else Object.assign(body, { deleteRole: true });
                    }
                    else if (value === 'permissions') {
                        Object.assign(body, { permissions: getPermissionsList });
                    }
                    else {
                        Object.assign(body, { [value]: values[value] });
                    }
                });
                patch(`users/${data._id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/users',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <UserFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleResult={handleResult}
            handleDateChange={handleDateChange}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            loadingPermissions={loadingPermissions}
            loadingRoles={loadingRoles}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewUser={isNewUser}
            permissions={permissions}
            allRoles={allRoles}
            handleChangePermission={handleChangePermission}
            handleChangeCategory={handleChangeCategory}
        />
    );
};

export default UserFormContainer;