import React from 'react';

import { EMAIL_REGEX } from "../../../constants/regex";

export default (values) => {
    let errors = {};

    if (!values.emailAddresses) {
        errors.emailAddresses = 'Ingrese direcciones de e-mail para invitar a registrarse';
    } else {
        const parsedEmailAddresses = values.emailAddresses.trim().replace(/\s\s+/g, ' ').split(/\s/);
        const invalidEmailAddresses = parsedEmailAddresses.filter(address => !EMAIL_REGEX.test(address));

        if (invalidEmailAddresses.length) {
            errors.emailAddresses = (
                <>
                    Algunas direcciones de e-mail son inválidas:
                    {
                        invalidEmailAddresses.map(address => (
                            <>
                                <br />
                                - {address}
                            </>
                        ))
                    }
                </>
            );
        } else {
            values.parsedEmailAddresses = parsedEmailAddresses;
        }
    }

    return errors;
}
