import React from "react";
import { CircularProgress, withStyles } from "@material-ui/core";

const styles = () => ({
    container: {
        display:'flex',
        flexDirection: 'column',
        justifyContent:'center',
        alignItems: 'center',
        height: '60vh'
    },
    text: {
        fontSize: 16,
    }
});

const Loading = ({ classes }) => (
    <div className={classes.container}>
        <CircularProgress color="primary"/>
        <br/>
        <p className={classes.text}>
            Obteniendo información...
        </p>
    </div>
);

export default withStyles(styles)(Loading);