import { validateAgeFields } from "../../../utils/utils";
import { POSITIVE_NUMBER_REGEX } from '../../../constants/regex';
import { values as durationsValues } from "../durations";

export default (values) => {
    let errors = {};

    if (!values.type) {
        errors.type = "Seleccione un Tipo de Abono"
    }

    if (!values.description) {
        errors.description = "Ingrese la descripción";
    }

    if (!values.price) {
        errors.price = "Ingrese el precio";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.price)) {
        errors.price = "El precio debe ser un número no negativo"
    }

    if (!values.lifelongPrice) {
        errors.lifelongPrice = "Ingrese el precio para vitalicios";
    } else if (!POSITIVE_NUMBER_REGEX.test(values.lifelongPrice)) {
        errors.lifelongPrice = "El precio para vitalicios debe ser un número no negativo"
    }

    validateAgeFields(values, errors);

    if (!values.duration) {
        errors.duration = "Seleccione una duración";
    } else if (!durationsValues().includes(values.duration)) {
        errors.duration = "Selecciona una duración válida"
    }

    return errors;
}