import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

export default function CustomAutocomplete({ readOnly, label, error, endAdornment, ...props }) {
    const [open, setOpen] = useState(false);

    return (
        <Autocomplete
            {...props}
            open={open}
            onOpen={() => !readOnly && setOpen(true)}
            onClose={() => setOpen(false)}
            disableClearable={readOnly}
            forcePopupIcon={!readOnly}
            ChipProps={{ ...(readOnly && { clickable: !readOnly, onDelete: null }) }}
            renderInput={({
                InputLabelProps,
                ...params }) =>
                <TextField
                    {...params}
                    InputLabelProps={{
                        ...InputLabelProps, ...(readOnly && { focused: false })
                    }}
                    label={label}
                    error={error !== undefined}
                    helperText={error}
                    InputProps={{
                        readOnly,
                        disableUnderline: readOnly,
                        ...params.InputProps,
                        ...(endAdornment && { endAdornment, style: { paddingRight: 0 } })

                    }}
                />
            }
        />
    );

};