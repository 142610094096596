export default [
    {
        id: 'firstName',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'lastName',
        label: 'Apellido',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'role',
        field: 'role',
        label: 'Rol',
        minWidth: 120
    },
    {
        id: 'email',
        label: 'Correo electrónico',
        minWidth: 150,
        hidden: { mdDown: true }
    }
];
