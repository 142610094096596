import { Typography } from '@material-ui/core';
import React from "react";
import moment from 'moment';
import 'moment/locale/es';
import { POSITIVE_INT_REGEX, NAME_WITH_NUMBERS_REGEX } from '../constants/regex';

export const sleep = (timeMS, onTimer) => new Promise((resolve, reject) => setTimeout(() => onTimer(resolve, reject), timeMS));

export const deleteCookie = name => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;';
};

export const getCookie = name => {
    let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
};

export const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

export const isLogged = () => getCookie('isLogged');
export const logout = () => deleteCookie('isLogged');

/**
 *  Converts a time in 24hour format into a decimal representation
 * @param {String} timeStr String of time in 24h format
 * @example stringToDecimalTime("10:30") //Returns: 10.5
 */
export const stringToDecimalTime = (timeStr) => {
    const strTime = timeStr.toString().split(":");

    const hour = parseInt(strTime[0]);

    const minute = parseFloat((parseInt(strTime[1]) / 60).toFixed(2));

    return hour + minute
}

export const genericOnRangeChange = (onRangeChange, loadMonth = false) => (rangeObject) => {
    let startMoment, endMoment;
    if (Array.isArray(rangeObject)) {
        if (rangeObject.length === 1) {
            startMoment = moment(rangeObject[0]);
            endMoment = startMoment.clone().add(1, 'days');
        }
        else if (rangeObject.length === 7) {
            startMoment = moment(rangeObject[0]);
            endMoment = moment(rangeObject[6]).add(1, 'days');
        }
    }
    else if (loadMonth) {
        const { start, end } = rangeObject;
        startMoment = moment(start);
        endMoment = moment(end);
    }
    else
        return

    onRangeChange(startMoment.toISOString(), endMoment.toISOString());
}

const ISHEX = /^[a-f\d]{24}$/i
export const isMongoId = (str) => ISHEX.test(str);

export const NoEditComponent = (props) => (
    props.isInEdit &&
    <Typography variant="caption" display="block" gutterBottom>
        {(props.isNew) && "Este campo no podrá ser editado luego." || "No es posible editar este campo."}
    </Typography>

)

export const validateAgeFields = (values, errors) => {
    if (values.ageFrom && !POSITIVE_INT_REGEX.test(values.ageFrom)) {
        errors.ageFrom = "La edad mínima debe ser un número no negativo"
    }

    if (values.ageTo && !POSITIVE_INT_REGEX.test(values.ageTo)) {
        errors.ageTo = "La edad máxima debe ser un número no negativo"
    }

    if (values.ageFrom && values.ageTo && +values.ageFrom >= +values.ageTo) {
        errors.ageFrom = "La edad mínima debe ser menor a la edad máxima"
        errors.ageTo = "La edad máxima debe ser mayor a la edad mínima"
    }
}

export const validateNameWithNumbers = (values, errors) => {
    if (!NAME_WITH_NUMBERS_REGEX.test(values.name))
        errors.name = "El nombre solo puede contener letras, números y espacios.";
}

export const getChangedValues = (data, values, fieldsToOmit = []) => {
    const changedValues = [];
    Object.keys(values).forEach(key => {
        data[key] !== values[key] && changedValues.push(key);
    });

    const totalFieldsToOmit = [...fieldsToOmit, 'created'];

    return changedValues.filter(value => !totalFieldsToOmit.includes(value));
};

export const objectIsEmpty = object => Object.keys(object).length === 0;