import moment from 'moment';
import 'moment/locale/es';
import { NAME_REGEX, PASSWORD_REGEX, POSITIVE_INT_REGEX } from '../../constants/regex';

export default (values) => {
    let errors = {};

    if (!values.firstName) {
        errors.firstName = "Ingrese el nombre";
    } else if (!NAME_REGEX.test(values.firstName)) {
        errors.firstName = "El nombre debe contener solo letras"
    }

    if (!values.lastName) {
        errors.lastName = "Ingrese el apellido";
    } else if (!NAME_REGEX.test(values.lastName)) {
        errors.lastName = "El apellido debe contener solo letras"
    }

    if (!values.password) {
        errors.password = "Ingrese la contraseña";
    } else if (values.password.length < 8 || values.password.length > 20) {
        errors.password = "La contraseña debe tener entre 8 y 20 caracteres"
    } else if (!PASSWORD_REGEX.test(values.password)) {
        errors.password = "La contraseña debe tener al menos un caracter en minúscula, uno en mayúscula y un numéro";
    } else if (!values.confirmPassword) {
        errors.confirmPassword = "Vuelva a escribir la contraseña";
    } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Las contraseñas no coinciden";
    }

    if (!values.birthdate) {
        errors.birthdate = "Ingrese la fecha de nacimiento";
    } else if (values.birthdate.isAfter(moment())) {
        errors.birthdate = "La fecha de nacimiento debe ser pasada"
    }

    if (values.phoneNumber && !POSITIVE_INT_REGEX.test(values.phoneNumber)) {
        errors.phoneNumber = "El teléfono debe ser un número entero positivo";
    }

    if (values.memberNumber && !POSITIVE_INT_REGEX.test(values.memberNumber)) {
        errors.memberNumber = "El número de socio debe ser un número entero positivo";
    }

    return errors;
}