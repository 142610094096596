import React, { Component } from 'react';
import { withTheme, Tooltip, IconButton } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import { Link } from 'react-router-dom';
import { EmojiEvents } from '@material-ui/icons/index';

const MESSAGES = {
    created: 'El seleccionado ha sido creado correctamente',
    edited: 'El seleccionado ha sido editado correctamente',
    deleted: 'El seleccionado ha sido eliminado correctamente'
};

class SportTeamsTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            sportTeams: [],
            sportTeamsCount: 0,
            loading: true,
            updating: false,
            successMessage: ''
        }
    }

    componentDidMount() {
        this.getSportTeams();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    extraActions = (sportTeam) => {
        return (
            <Link to={{
                pathname: `/home/sportteams/${sportTeam._id}/tournaments`,
                state: sportTeam._id
            }}>
                <Tooltip title="Ver Torneos">
                    <IconButton>
                        <EmojiEvents />
                    </IconButton>
                </Tooltip>
            </Link>
        )
    };

    getSportTeams = () => {
        get('sportteams?page=0')
            .then(res => handleResponse(res, this.props))
            .then(parsed => {

                this.setState({
                    sportTeams: parsed.message.sportTeams,
                    sportTeamsCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            }
            )
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                get('sportteams?page=' + page)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed =>
                        this.setState(prevState => ({
                            sportTeams: prevState.sportTeams.concat(parsed.message.sportTeams),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id) => id ? this.props.history.push('/home/sportteams/' + id)
        :
        this.props.history.push('/home/sportteams/new');

    handleDelete = sportTeamId => {
        const sportTeam = this.state.sportTeams.find(u => u._id === sportTeamId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar seleccionado",
            text: `¿Estás seguro de que querés eliminar el seleccionado '${sportTeam.name}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar seleccionado',
            confirmButtonText: 'Sí, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`sportteams/${sportTeam._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        sportTeams: prevState.sportTeams.filter(r => r._id !== sportTeamId),
                                        sportTeamsCount: prevState.sportTeamsCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getSportTeams())
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    render() {
        return (
            <TableComponent
                data={this.state}
                extraActions={this.extraActions}
                path="/home/sportteams/"
                newMessage="Nuevo seleccionado"
                columns={COLUMNS}
                dataKey="sportTeams"
                handleOpenForm={this.handleOpenForm}
                handleDelete={this.handleDelete}
                handleChangePage={this.handleChangePage}
                onCloseToast={this.onCloseToast}
                successMessage={this.state.successMessage}
            />
        )
    }
}

export default withTheme(SportTeamsTableContainer);
