import React, { useState, useEffect } from 'react';
import { handleResponse, post, patch, swal500, del, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateFacility from './validator';
import * as Swal from "sweetalert2";
import FacilityFormComponent from './FacilityFormComponent';
import { getChangedValues, objectIsEmpty } from '../../../utils/utils';

export const FIELDS = {
    description: "Descripción",
    enabled: "Habilitar/deshabilitar",
    openingIntervals: "Horarios",
    type: "Tipo de instalación"
};

const INITIAL_STATE = {
    _id: '',
    description: '',
    enabled: true,
    openingIntervals: [],
    type: null,
    detailImage: ""
};

const FacilityFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [localImagePath, setLocalImagePath] = useState(null);

    const {
        handleSubmit,
        handleChange,
        handleChanges,
        runValidations,
        handleBlur,
        values,
        errors,
        setErrors,
        mergeErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateFacility(values));
    const isNewFacility = objectIsEmpty(data);

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);

                const internalName = field.name.split('[')[0];

                errors[internalName] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    const handleClose = () => props.history.push('/home/facilities');

    useEffect(() => {
        if (props.match.params.id === 'new') {
            setEditMode(true);
            setLoading(false);
            return;
        } else if (props.location.state) {

            const stateKeys = Object.keys(INITIAL_STATE);
            const { state } = props.location

            const validKeys = {}

            //copy only valid fields
            stateKeys.forEach(k => {
                if (state.hasOwnProperty(k))
                    validKeys[k] = state[k]
            });

            if (Object.keys(validKeys).length === stateKeys.length) {
                setData(validKeys)
                resetValues(validKeys);
                setLoading(false);
                return;
            }
        }

        handleClose();
    }, [props, resetValues]);


    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar instalación",
            text: `¿Estás seguro de que querés eliminar la Instalación '${values.description}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar instalación',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`facilities/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/facilities',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {

        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewFacility) {
                const formData = new FormData();
                formData.append('description', values.description);
                formData.append('enabled', values.enabled);
                formData.append('openingIntervals', JSON.stringify(values.openingIntervals));
                formData.append('type', values.type._id);

                if (typeof values.detailImage == "object")
                    formData.append('detailImage', values.detailImage);

                post('facilities', formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/facilities',
                            state: { msg: 'created' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const changedValues = getChangedValues(data, values, ['type']);
                if (changedValues.length === 0) {
                    props.history.push('/home/facilities');
                    return;
                }

                const formData = new FormData();

                changedValues.forEach(value => {
                    if (value === "openingIntervals")
                        formData.append([value], JSON.stringify(values[value]));
                    else
                        formData.append([value], values[value]);
                });

                patch(`facilities/${data._id}`, formData, true, { 'Accept': 'Application/json' }, true)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/facilities',
                            state: { msg: 'edited' },
                        });
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    const handleImageChange = ({ target }) => {
        handleChange({
            target: {
                name: "detailImage",
                value: target.files[0]
            }
        })
    }

    const detailImage = values.detailImage;

    useEffect(() => {
        if (typeof detailImage == "object")
            setLocalImagePath(URL.createObjectURL(detailImage))

    }, [detailImage]);

    useEffect(() => {
        handleBlur({
            target: {
                name: "detailImage"
            }
        })
    }, [localImagePath]);

    const isEditable = () => !values.erasedOnNextPeriod

    return (
        <FacilityFormComponent
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleImageChange={handleImageChange}
            handleResult={handleResult}
            handleDelete={handleDelete}
            loading={loading}
            submitting={submitting}
            data={data}
            values={values}
            editMode={editMode}
            setEditMode={setEditMode}
            isNewFacility={isNewFacility}
            localImagePath={localImagePath}
            isEditable={isEditable()}
            handleChanges={handleChanges}
            runValidations={runValidations}
            setErrors={setErrors}
            mergeErrors={mergeErrors}
            location={props.location}
            history={props.history}
        />
    );
};

export default FacilityFormContainer;