export default [
    {
        id: 'role',
        label: 'Rol',
        minWidth: 100,
    },
    {
        id: 'usersCount',
        label: 'Cantidad de usuarios',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
];