import React, { useState, useEffect } from 'react';
import { handleResponse, patch, swal500, get } from '../../../utils/network';
import { useFormValidation } from "../../common";
import validateReservation from './validator';
import ReservationsFormComponent from "./ReservationFormComponent";
import moment from 'moment';
import 'moment/locale/es';

const internalErrorNames = {
    user: 'memberNumber',
    reservation: 'start'
};


export const FIELDS = {
    facility: "Instalación",
    shiftDuration: 'Duración del turno',
    shiftPrice: 'Precio del turno',
    memberNumber: "Número de socio",
    firstName: "Nombre",
    lastName: "Apellido",
    email: 'Correo electrónico',
    start: 'Desde',
    end: 'Hasta'
};

const INITIAL_STATE = {
    memberNumber: null,
    reservor: null,
    start: null,
    end: null
};

const ReservationFormContainer = (props) => {
    const { facility, reservation, handleClose, handleDelete, setMessage, addReservation } = props;
    const [submitting, setSubmitting] = useState(false);
    const [isPastReservation, setIsPastReservation] = useState(false);

    const {
        handleSubmit,
        handleChange,
        handleChanges,
        handleCheckBox,
        handleDateChange,
        handleBlur,
        values,
        errors,
        resetError,
        setErrors,
        mergeErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateReservation(values));

    useEffect(() => {
        resetValues(reservation);
        if (moment().isAfter(reservation.start)) {
            setIsPastReservation(true);
        }
    }, [reservation, resetValues]);

    useEffect(() => {
        const fetchMemberData = (memberNumber) => {
            setSubmitting(true);
            get(`users?page=0&memberNumber=eq:${memberNumber}`)
                .then(res => handleResponse(res, props))
                .then(res => {
                    setSubmitting(false);
                    const responseUser = res.message.data;
                    if (responseUser.length) {
                        resetError('memberNumber');
                        handleChanges({ reservor: responseUser[0] })
                    }
                    else {
                        mergeErrors({ memberNumber: "No existe un socio con ese número" })
                        handleChanges({ reservor: null });
                    }
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        return swal500(err);
                    }

                    setErrors((prevstate) => ({ ...prevstate, "memberNumber": "No se encontro a nadie con ese número de socio!" }))
                });
        }
        if (reservation?.free) {
            if (values.memberNumber) {
                const typeTimeout = setTimeout(() => fetchMemberData(values.memberNumber), 500)
                return () => clearTimeout(typeTimeout)
            } else if (values.reservor) {
                handleChanges({ reservor: null });
            }
        }
    }, [reservation?.free, values.memberNumber]);

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const { name } = field;
                const internalName = internalErrorNames[name] ?? name;
                errors[internalName] = field.message.replace(name, FIELDS[name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };



    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            patch(`facilities/${facility._id}/reserve`, {
                user_id: values.reservor._id,
                start: values.start
            })
                .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                .then(res => {
                    setSubmitting(false);
                    const start = new Date(res.message.start);
                    const end = new Date(res.message.end);
                    const reservor = { ...values.reservor, memberNumber: values.memberNumber };
                    const newReservation = { ...res.message, start, end, reservor };
                    addReservation(newReservation);
                    setMessage('created');
                    handleClose();
                })
                .catch(err => {
                    setSubmitting(false);
                    if (err !== 400) {
                        swal500(err);
                    }
                });
        }
    };

    return (
        <ReservationsFormComponent
            errors={errors}
            handleChange={handleChange}
            handleCheckBox={handleCheckBox}
            handleBlur={handleBlur}
            handleClose={handleClose}
            handleDateChange={handleDateChange}
            handleResult={handleResult}
            handleDelete={() => handleDelete(reservation)}
            loading={false}
            submitting={submitting}
            values={values}
            facility={facility}
            isNewReservation={reservation?.free}
            isPastReservation={isPastReservation}
        />
    );
};

export default ReservationFormContainer;