export default [
    {
        id: 'name',
        label: 'Nombre',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    },
    {
        id: 'address',
        label: 'Dirección',
        minWidth: 100,
        hidden: {
            xsDown: true
        }
    }
];
