import {
    Button, Card, CircularProgress,
    IconButton, Switch, TextField, Tooltip, Typography, withStyles
} from "@material-ui/core";
import { Assignment, Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React from "react";
import { NoEditComponent } from "../../../utils/utils";
import { ApiAutocomplete, FormLayout } from "../../common";
import WeekScheduler from "../WeekScheduler/WeekScheduler";
import { FIELDS } from "./FacilityFormContainer";
import styles from "./styles";

const FacilityFormComponent = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleBlur,
        handleClose,
        handleResult,
        handleDelete,
        handleImageChange,
        submitting,
        loading,
        data,
        values,
        editMode,
        setEditMode,
        isNewFacility,
        localImagePath,
        isEditable
    } = props;

    const renderActions = () => (
        <div className={classes.topActionsContainer}>
            {isEditable ?
                <div>
                    {!isNewFacility &&
                        (<Tooltip title={FIELDS['enabled']}>
                            <Switch
                                checked={values.enabled}
                                onChange={({ target }) => { handleChange({ target: { name: "enabled", value: target.checked } }) }}
                                name="enabled"
                                color="primary"
                                disabled={!editMode}
                            />
                        </Tooltip>
                        )
                    }
                    {!isNewFacility &&
                        (<Tooltip title="Ver y administrar reservas">
                            <IconButton aria-label="reservas" onClick={() => props.history.push(
                                {
                                    pathname: `/home/facilities/${props.location.state._id}/reservations`,
                                    state: data
                                })}>
                                <Assignment />
                            </IconButton>
                        </Tooltip>)
                    }
                    {!editMode && (
                        <IconButton onClick={() => setEditMode(true)}>
                            <CreateIcon />
                        </IconButton>
                    )}
                    {!isNewFacility &&
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    }
                </div>
                : <p>No es posible editarlo</p>}
        </div>
    );
    const ImageCard = () => (
        <div>
            <Typography variant="caption" gutterBottom className={classes.imageLabel}>
                Imagen
            </Typography>
            <Card elevation={3} className={classes.imageContainer}>
                <img alt="Imagen de la instalación" src={localImagePath ?? values.detailImage} className={classes.image} />
                {errors.detailImage ?
                    <Typography variant="h6" gutterBottom className={classes.imageLabelError}>
                        {errors.detailImage}
                    </Typography>
                    : null}
            </Card>
            {editMode &&
                <div className={classes.imageCardActions}>
                    <input
                        accept="image/*"
                        type='file'
                        onChange={handleImageChange}
                        id="upload-file"
                        className={classes.inputFile}
                    />
                    <label className={classes.imageCardLabel} htmlFor="upload-file">
                        {submitting &&
                            <CircularProgress color="primary" size={22} style={{ marginRight: 10 }} />
                        }
                        <Button
                            component='span'
                            color='primary'
                            variant='contained'
                            disabled={loading || submitting}
                            className={classes.imageButton}
                        >
                            Actualizar imagen
                        </Button>
                    </label>
                </div>
            }
        </div>
    );

    const handleAutocompleteChange = (name) => (e, value) => {
        handleChange({ target: { name, value } })
    }

    return (
        <FormLayout
            handleClose={handleClose}
            renderActions={renderActions}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNew={isNewFacility}
            dataKey="Instalación"
        >
            <ApiAutocomplete
                error={errors.type}
                handleChange={handleChange}
                label='name'
                name='type'
                path='facility-types?app=bof&name=like:'
                title={FIELDS.type}
                value={values.type}
                noOptionsText="No hay Tipos de Instalación con ese nombre"
                disabled={!isNewFacility}
                dataField="facilityTypes"
            />
            <NoEditComponent isNew={isNewFacility} isInEdit={editMode} />

            {editMode && <div className={classes.divider} />}
            <TextField
                name='description'
                error={errors.description !== undefined}
                helperText={errors.description}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.description}
                label={FIELDS['description']}
                className={classes.textField}
                InputProps={{
                    readOnly: !editMode || submitting,
                    disableUnderline: !editMode
                }}
            />

            {editMode && <div className={classes.divider} />}
            <Typography variant="caption" gutterBottom className={classes.imageLabel}>
                Horarios y precios
            </Typography>

            {editMode && <div className={classes.divider} />}
            <WeekScheduler {...props} FIELDS={FIELDS} classes={null} />

            {editMode && <div className={classes.divider} />}
            <ImageCard />


        </FormLayout>
    );
};

export default withStyles(styles)(FacilityFormComponent);