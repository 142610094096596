import React, { useEffect, useState } from 'react';
import RoleFormComponent from "./RoleFormComponent";
import { del, get, handleResponse, patch, post, swal500 } from "../../../utils/network";
import { useFormValidation, usePermissionSelector } from "../../common";
import validateRole from "./validator";
import * as Swal from "sweetalert2";
import { objectIsEmpty } from '../../../utils/utils';

const FIELDS = {
    role: 'Nombre del rol'
};

const INITIAL_STATE = {
    role: '',
    permissions: []
};

const RoleFormContainer = (props) => {
    const [data, setData] = useState({});
    const [editMode, setEditMode] = useState(props.location.editMode || false);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [allPermissions, setAllPermissions] = useState([]);
    const isNewRole = objectIsEmpty(data);

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        setErrors,
        resetValues
    } = useFormValidation(INITIAL_STATE, values => validateRole(values, getPermissionsList));

    const {
        handleChangePermission,
        handleChangeCategory,
        permissions,
        setPermissions,
        getPermissionsList,
        permissionsDidChange
    } = usePermissionSelector();

    const getChangedValues = values => {
        const changedValues = [];
        Object.keys(data).forEach(key => {
            data[key] !== values[key] && changedValues.push(key)
        });
        if (permissionsDidChange(data.permissions.map(p => p._id))) {
            changedValues.push('permissions');
        }
        return changedValues;
    };

    const handle400 = err => {
        err.text().then(error => {
            let errorObject = JSON.parse(error);
            let errors = {};
            errorObject.fields.forEach(field => {
                const regex = new RegExp(field.name);
                errors[field.name] = field.message.replace(regex, FIELDS[field.name]);
            });
            setErrors(errors);
        });
        return Promise.reject(400);
    };

    useEffect(() => {
        const getPermissions = () => {
            setLoadingPermissions(true);
            get('permissions')
                .then(res => handleResponse(res, props))
                .then(res => {
                    setLoadingPermissions(false);
                    setAllPermissions(res.message);
                })
                .catch(e => {
                    if (e !== 403) {
                        swal500(e);
                    }
                    setLoadingPermissions(false);
                    props.history.push('/home/roles');
                });
        };

        const getRole = () => {
            setLoading(true);
            get('roles/' + props.match.params.id)
                .then(res => handleResponse(res, props))
                .then(resRole => {
                    setLoadingPermissions(true);
                    get('permissions')
                        .then(res => handleResponse(res, props))
                        .then(resPermission => {
                            setLoadingPermissions(false);
                            setData(resRole.message);
                            setAllPermissions(resPermission.message);
                            resetValues(resRole.message);
                            setLoading(false);
                        })
                        .catch(e => {
                            if (e !== 403) {
                                swal500(e);
                            }
                            setLoadingPermissions(false);
                            props.history.push('/home/roles');
                        });
                })
                .catch(err => {
                    if (err !== 403) {
                        swal500(err);
                    }
                    props.history.push('/home/roles');
                })
        };

        if (props.match.params.id === 'new') {
            setEditMode(true);
            getPermissions();
            setLoading(false);
        } else {
            getRole();
        }
    }, [props, resetValues]);

    useEffect(() => {
        const buildPermissions = () => {
            return allPermissions
                .map(permission => {
                    if (!permission.category) return { ...permission, category: "Otros" };
                    return permission;
                })
                .map(permission => ({
                    ...permission,
                    permissions: permission.permissions.map(p => ({
                        ...p,
                        checked: data?.permissions?.map(p => p._id).includes(p._id) || false,
                        disabled: !editMode
                    }))
                }));
        };

        if (allPermissions.length) {
            setPermissions(buildPermissions());
        }
    }, [allPermissions, editMode, data, setPermissions]);

    const handleClose = () => props.history.push('/home/roles');

    const handleDelete = () => {
        Swal.fire({
            title: "Eliminar rol",
            text: "¿Estas seguro de que queres eliminar al rol " + values.role + "?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#4eaaf3', //TODO: theme.palette.primary.main
            confirmButtonColor: "#FF0000", //TODO: theme.palette.state.failure
            cancelButtonText: 'No, conservar rol',
            confirmButtonText: 'Sí, estoy seguro',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                Swal.update({ showCancelButton: false })
                return del(`roles/${props.match.params.id}`)
                    .then(res => handleResponse(res, props))
                    .then(result =>
                        props.history.push({
                            pathname: '/home/roles',
                            state: { msg: 'deleted' },
                        })
                    )
                    .catch(err => {
                        swal500(err);
                    });
            }
        });
    };

    const handleResult = (e) => {
        const formValid = handleSubmit(e);
        if (formValid) {
            setSubmitting(true);
            if (isNewRole) {
                post('roles', {
                    role: values.role,
                    permissions: getPermissionsList
                })
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/roles',
                            state: { msg: 'created' },
                        })
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            } else {
                const body = {};
                const changedValues = getChangedValues(values);
                if (changedValues.length === 0) {
                    props.history.push('/home/roles');
                    return;
                }
                changedValues.forEach(value => {
                    if (value === 'permissions') {
                        Object.assign(body, { permissions: getPermissionsList });
                    }
                    else {
                        Object.assign(body, { [value]: values[value] });
                    }
                });
                patch(`roles/${props.match.params.id}`, body)
                    .then(res => handleResponse(res, props, [{ status: 400, method: handle400 }]))
                    .then(res => {
                        setSubmitting(false);
                        props.history.push({
                            pathname: '/home/roles',
                            state: { msg: 'edited' },
                        })
                    })
                    .catch(err => {
                        setSubmitting(false);
                        if (err !== 400) {
                            swal500(err);
                        }
                    });
            }
        }
    };

    return (
        <RoleFormComponent
            handleClose={handleClose}
            loading={loading}
            editMode={editMode}
            submitting={submitting}
            handleSubmit={handleResult}
            isNewRole={isNewRole}
            setEditMode={setEditMode}
            handleDelete={handleDelete}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            permissions={permissions}
            loadingPermissions={loadingPermissions}
            handleChangePermission={handleChangePermission}
            handleChangeCategory={handleChangeCategory}
        />
    );
};


export default RoleFormContainer;