export default theme => {
    console.log(theme)
    return {
        button: {
            marginBottom: 20,
            borderRadius: 10,
            backgroundColor: theme.palette.clubstar?.main ?? theme.palette.primary.main,
        },
        clubTextField: {
            display: 'flex',
            width: 100
        },
        clubDomInput: {
            textAlign: 'center',
            padding: 2
        },
        clubInput: {
            fontSize: 20
        },
        clubContainer: {
            border: `1px solid ${theme.palette.clubstar?.main ?? theme.palette.primary.main}`,
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            width: 'max-content',
            marginBottom: 70,
            padding: '5px 15px',
        },
        clubInputContainer: {
            display: 'flex',
            flexDirection: 'row'
        },
        clubInputLabel: {
            color: '#4D4D4D',
            marginLeft: 5,
            fontSize: 20,
            verticalAlign: 'bottom',
        },
        submitContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'max-content',
        },
        formOnboarding: {
            marginTop: "2.5%",
            marginBottom: "2.5%",
            width: '30rem',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: "35px 50px 35px 50px",
            borderRadius: 5,
            boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.75)',
            [theme.breakpoints.down(516)]: {
                minHeight: '80vh',
                maxHeight: '90vh',
                maxWidth: '80%',
                padding: 10,
                margin: 0,
                boxShadow: "none",
                borderRadius: 0,
            },
            backgroundColor: 'white',
            borderBottom: `10px solid ${theme.palette.clubstar?.main ?? theme.palette.primary.main}`
        },
        gridOnboarding: {
            minHeight: '100vh'
        },
        title: {
            color: '#4D4D4D',
            marginBottom: 40,
            marginTop: 40,
            fontSize: 30,
        }
    }
};
