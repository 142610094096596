import React, { Component } from 'react';
import { Card, Checkbox, FormControlLabel, Grid, IconButton, TextField, withStyles, withTheme } from '@material-ui/core';
import * as Swal from 'sweetalert2';
import { handleResponse, get, del, swal500 } from '../../../utils/network';
import { TableComponent } from "../../common";
import COLUMNS from "./columns";
import styles from './styles'
import moment from 'moment';
import 'moment/locale/es';
import { Search } from '@material-ui/icons';

const MESSAGES = {
    created: 'El invitado ha sido creado correctamente',
    edited: 'El invitado ha sido editado correctamente',
    deleted: 'El invitado ha sido eliminado correctamente'
};

class InviteesTableContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            maxPageFetched: 0,
            editing: false,
            invitees: [],
            inviteesCount: 0,
            loading: true,
            updating: false,
            successMessage: '',
            dateFilter: false,
            dateField: moment(),
            dniFilter: ""
        }
    }

    componentDidMount() {
        this.getInvitees();
        const msgKey = this.props.location.state?.msg;
        if (msgKey) {
            const msg = MESSAGES[msgKey];
            msg && this.setState({ successMessage: msg });
        }
    };

    getFetchUrl = (page) => {
        const filters = [];
        if (this.state.dniFilter !== '')
            filters.push(`dni=eq:${this.state.dniFilter}`);
        if (this.state.dateFilter)
            filters.push(`date=${this.state.dateField.format()}`);
        const queryParams = [`page=${page}`, ...filters];
        return `invitees?${queryParams.join('&')}`
    }

    formatInvitees = (invitees) =>
        invitees.map(inv => ({
            ...inv,
            hasAttended: inv.attended ? "Si" : "No",
            dateFormatted: moment(inv.date).format('DD/MM/YYYY')
        }));


    getInvitees = () => {
        const fetchUrl = this.getFetchUrl(0);
        get(fetchUrl)
            .then(res => handleResponse(res, this.props))
            .then(parsed => {
                const formattedInvitees = this.formatInvitees(parsed.message.invitees)
                this.setState({
                    invitees: formattedInvitees,
                    inviteesCount: parsed.message.count,
                    page: 0,
                    maxPageFetched: 0,
                    loading: false,
                    updating: false
                })
            })
            .catch(err => {
                this.setState({ loading: false, updating: false });
                swal500(err);
            });
    };

    showMore = (page) => {
        this.setState(
            { updating: true },
            () => {
                const fetchUrl = this.getFetchUrl(page);

                get(fetchUrl)
                    .then(res => handleResponse(res, this.props))
                    .then(parsed => {
                        const formattedInvitees = this.formatInvitees(parsed.message.invitees)
                        this.setState(prevState => ({
                            invitees: prevState.invitees.concat(formattedInvitees),
                            maxPageFetched: page,
                            loading: false,
                            updating: false
                        }))
                    }
                    )
                    .catch(err => {
                        this.setState({ loading: false, updating: false });
                        swal500(err);
                    });
            }
        )
    };

    handleChangePage = (event, newPage) => {
        this.setState(
            { page: newPage },
            () => {
                window.scrollTo(0, 0);
                if (newPage > this.state.maxPageFetched) {
                    this.showMore(newPage);
                }
            }
        );
    };

    onCloseToast = (toastKey) => () => this.setState({ [toastKey]: '' });

    handleOpenForm = (id, stateToSend) => id ? this.props.history.push({
        pathname: '/home/invitees/' + id,
        state: stateToSend,
    })
        :
        this.props.history.push('/home/invitees/new');

    handleDelete = inviteeId => {
        const invitee = this.state.invitees.find(u => u._id === inviteeId);
        const { palette } = this.props.theme;
        Swal.fire({
            title: "Eliminar invitado",
            text: `¿Estás seguro de que querés eliminar el invitado '${invitee.firstName}'?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: palette.primary.main,
            confirmButtonColor: palette.state.failure,
            cancelButtonText: 'No, conservar invitado',
            confirmButtonText: 'Sí, estoy seguro',
        }).then(result => {
            if (result.value) {
                this.setState(
                    { updating: true },
                    () => {
                        del(`invitees/${invitee._id}`)
                            .then(res => handleResponse(res, this.props))
                            .then(result =>
                                this.setState(
                                    (prevState) => ({
                                        invitees: prevState.invitees.filter(r => r._id !== inviteeId),
                                        inviteesCount: prevState.inviteesCount - 1,
                                        updating: false,
                                        successMessage: MESSAGES.deleted
                                    })
                                ))
                            .then(res => this.getInvitees())
                            .catch(err => {
                                swal500(err);
                                this.setState({ updating: false });
                            });
                    }
                );
            }
        })
    };

    navStateSelector = ({ _id, firstName, lastName, dni, date, attended, inviter }) => ({ _id, firstName, lastName, dni, date, attended, inviter })

    handleCheckBox = (event) => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked });
    };

    handleFilter = () => this.getInvitees();

    handleTextFieldChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: value });
    }

    handleDateChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: moment(value) });
    }

    keyPress = (e) => {
        if (e.keyCode === 13)
            this.handleFilter()
    }

    isEditable = (row) => moment().isSameOrBefore(row.date, 'day') && !row.attended

    isDeletable = (row) => !row.attended

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Card elevation={3} className={classes.header}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                    >
                        <h2>Invitados</h2>
                        <div className={classes.dateInput}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.dateFilter}
                                        onChange={this.handleCheckBox}
                                        name='dateFilter'
                                    />
                                }
                                label='Filtrar por fecha'
                            />
                            <TextField
                                name="dateField"
                                label="Fecha de asistencia"
                                type="date"
                                className={classes.dateField}
                                disabled={!this.state.dateFilter}
                                helperText={this.state.dateFilter}
                                FormHelperTextProps={{ margin: 'dense' }}
                                defaultValue={this.state.dateField.format('YYYY-MM-DD')}
                                onChange={this.handleDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className={classes.searchInput} >
                            <TextField
                                name='dniFilter'
                                label="DNI de invitado"
                                type="number"
                                value={this.state.dniFilter}
                                onKeyDown={this.keyPress}
                                onChange={this.handleTextFieldChange}
                            />
                            <div className={classes.searchButton}>
                                <IconButton onClick={this.handleFilter} color="secondary">
                                    <Search />
                                </IconButton>
                            </div>
                        </div>
                    </Grid>
                </Card>
                <TableComponent
                    data={this.state}
                    path="/home/invitees/"
                    newMessage="Nuevo invitado"
                    columns={COLUMNS}
                    dataKey="invitees"
                    handleOpenForm={this.handleOpenForm}
                    handleDelete={this.handleDelete}
                    handleChangePage={this.handleChangePage}
                    onCloseToast={this.onCloseToast}
                    successMessage={this.state.successMessage}
                    navStateMapper={this.navStateSelector}
                    setEditable={this.isEditable}
                    setDeletable={this.isDeletable}
                />
            </div>
        )
    }
}

export default withTheme(withStyles(styles)(InviteesTableContainer));
