export default theme => ({
    background: {
        backgroundColor: '#efefef',
        [theme.breakpoints.down(516)]: {
            backgroundColor: 'white'
        }
    },
    backButton: {
        alignSelf: 'flex-start',
    },
    gridLogin: {
        minHeight: '100vh',
    },
    title: {
        marginBottom: 15,
        color: '#20485d',
        marginTop: 10,
        fontSize: 30,
    },
    formHeader: {
        display: 'flex',
        flexDirection: 'column',
    },
    formLogin: {
        marginTop: "2.5%",
        marginBottom: "2.5%",
        width: 400,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: "35px 50px 35px 50px",
        borderRadius: 5,
        boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.75)',
        [theme.breakpoints.down(516)]: {
            minHeight: '80vh',
            maxHeight: '90vh',
            maxWidth: '80%',
            padding: 10,
            margin: 0,
            boxShadow: "none",
            borderRadius: 0,
        },
        backgroundColor: 'white',
    },
    inputLogin: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    inputIcon: {
        marginRight: 5,
        color: "grey"
    },
    signInButton: {
        marginTop: 20,
        color: "white"
    },
    rememberContainer: {
        [theme.breakpoints.up(375)]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: 5
        }
    },
    loadingContainer: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        position: 'fixed',
        zIndex: 1
    },
    forgetPassword: {
        border: 'none',
        color: '#20485d',
        cursor: 'pointer'
    }
});
