export default (values) => {
    let errors = {};

    if (!values.title) {
        errors.title = "Ingrese el título";
    }

    if (!values.body) {
        errors.body = "Ingrese el cuerpo";
    }

    if (!values.topics.length) {
        errors.topics = "Ingrese los tópicos";
    }

    return errors;
}