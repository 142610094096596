import React, { useEffect, useState } from "react";
import {
    Button,
    IconButton,
    withStyles,
    Grid, TableContainer, Table, TableCell, Paper, TableHead, TableRow, Fab, TableBody,
    Dialog, DialogContent, DialogTitle, DialogActions, TextField
} from "@material-ui/core";
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons';
import styles from "./styles";
import { ApiAutocomplete } from "../../common";

const PlayerPicker = (props) => {
    const {
        classes,
        errors,
        handleChange,
        handleChanges,
        values,
        runValidations,
        editMode,
        mergeErrors
    } = props;

    const [modalOpen, setModalOpen] = useState(false);

    const { newPlayer } = values

    useEffect(() => {
        runValidations()
    }, [newPlayer])

    const closeDialog = () => {
        setModalOpen(false)
        mergeErrors({
            newPlayer: undefined
        })
        handleChanges({
            newPlayer: null
        })
    }

    const saveNewPlayer = () => {

        const datas = ["newPlayer"];

        const hasError = datas.some(d => errors[d])

        if (hasError)
            return;

        const incomplete = datas.find(d => !values[d]);

        if (incomplete) {
            mergeErrors({
                [incomplete]: "Ingrese el campo"
            })
            return;
        }

        handleChange({
            target: {
                name: "players",
                value: [
                    ...values.players,
                    values.newPlayer
                ]
            }
        })

        handleChanges({
            newPlayer: null
        })

        setModalOpen(false)
    }

    const removePlayer = (playerId) => {

        const remainingPlayers = values.players.filter(p => p._id !== playerId);

        const target = {
            name: "players",
            value: [
                ...remainingPlayers
            ]
        }

        handleChange({ target })
    }

    const TBody = () => {

        const players = values.players;

        if (!players || players.length === 0)
            return (
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="center">No hay jugadores</TableCell>
                </TableRow>
            );

        const sortedPlayers = players.sort()

        return (
            <TableBody>
                {sortedPlayers.map((player, index) => (
                    <TableRow key={index}>
                        <TableCell align="center">{player.firstName}</TableCell>
                        <TableCell align="center">{player.lastName}</TableCell>
                        {editMode && (<TableCell align="center"><IconButton onClick={() => removePlayer(player._id)} aria-label="delete"><DeleteIcon /></IconButton></TableCell>)}
                    </TableRow>
                ))}
            </TableBody>
        );
    }

    return (
        <div>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <TextField
                    name='players'
                    error={errors.players !== undefined}
                    helperText={errors.players}
                    className={classes.textField}
                    value="Jugadores"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                >

                </TextField>
                {editMode ?
                    <Fab color="primary" size="small" variant="extended" onClick={() => { setModalOpen(true) }}>
                        <AddIcon /> Agregar Jugador
                    </Fab>
                    : null
                }

            </Grid>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nombre/s</TableCell>
                            <TableCell align="center">Apellido/s</TableCell>
                            {editMode && (<TableCell align="center"></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TBody />
                </Table>
            </TableContainer>

            <Dialog open={modalOpen} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar Jugador</DialogTitle>
                <DialogContent className={classes.playerPicketInput}>

                    <ApiAutocomplete
                        error={errors.newPlayer}
                        handleChange={handleChange}
                        label={(user) => `${user.firstName} ${user.lastName}`}
                        name='newPlayer'
                        path='users?email=like:'
                        title="Jugador"
                        value={values.newPlayer}
                        noOptionsText="No hay socios con ese correo electrónico"
                        filterLabel={(value) => value.email}
                        disabled={!editMode}
                        dataField="data"
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={saveNewPlayer} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withStyles(styles)(PlayerPicker);